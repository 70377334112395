// src/Page/FaqSection.jsx

import React, { useState } from "react";
import { ACCORDION_DATA } from "../../Constants/myDharmaContent";

const FaqSection = () => { // FAQ section to display the frequently asked questions
  const [activeFaq, setActiveFaq] = useState(null);

  const toggleFaq = (id) => {
    setActiveFaq(activeFaq === id ? null : id);
  };

  return (
    <div className="container faq tw-w-[90%] md:tw-w-[90%]" id="faq">
      <h1 className="tw-text-center tw-font-[playfairdisplay] tw-font-[40px] tw-text-[#5A1740] sm:tw-font-[30px]">
        FAQ
      </h1>
      <div
        className="faq-container sm:tw-p-[40px]"
        style={{ fontFamily: "Playfair Display", fontSize: "14px" }}
      >
        <div className="faq-container-inner">
          <ul className="faq-list">
            {ACCORDION_DATA.map((faq) => (
              <li key={faq.id}>
                <button className="accordion" onClick={() => toggleFaq(faq.id)}>
                  {faq.question}
                  <img
                    style={{
                      transform: activeFaq === faq.id ? "rotate(180deg)" : "rotate(0deg)",
                    }}
                    className="faq-arrow-icon"
                    src="/assets/images/down-icon.png"
                    alt="Drop-Down-Icon"
                    loading="lazy"
                  />
                </button>
                <div
                  className="panel"
                  style={{
                    display: activeFaq === faq.id ? "block" : "none",
                  }}
                >
                  <p className="panel-text">{faq.answer}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FaqSection;