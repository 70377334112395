// CountryDropdown.js
import React, { useState, useRef, useEffect } from 'react';    // created new component for the country dropdown

export default function CountryDropdown({ selectedCountry, setSelectedCountry, COUNTRY_CODE }) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {    //function to handle the click outside the dropdown
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div ref={dropdownRef}>
      <div
        className="tw-w-[90px] tw-cursor-pointer tw-p-2 tw-border-solid tw-border-gray-500 
                   tw-border tw-border-r-0 tw-flex tw-items-center tw-justify-between"
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        <div>{"+" + selectedCountry.phone}</div>
        <img
          src="/assets/images/drop_down.webp"
          className="tw-ml-2 tw-w-3 tw-h-3 tw-object-contain"
          alt="Dropdown-Icon"
          loading="lazy"
        />
      </div>
      {isDropdownOpen && (
        <div
          className="tw-absolute tw-z-10 tw-w-[200px] tw-border tw-bg-white 
                     tw-shadow-lg tw-rounded-b tw-bg-[white]" //adjusted the styling according to the resolutions
        >
          <ul className="tw-max-h-60 tw-overflow-y-auto">
            {COUNTRY_CODE.map((country) => (
              <li
                key={country.value}
                className="tw-flex tw-justify-between tw-p-2 tw-cursor-pointer hover:tw-bg-gray-100"
                onClick={() => {
                  setSelectedCountry(country);
                  setIsDropdownOpen(false);
                }}
              >
                <span>+{country.phone}</span>
                <strong>{country.name}</strong>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}
