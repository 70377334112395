// Debounce function to limit the number of times a function is called 
export const debounce = (func, delay) => {
    let timeoutId;
    
    return (...args) => {
      return new Promise((resolve, reject) => {
        clearTimeout(timeoutId);

        timeoutId = setTimeout(async () => {
          try {
            const result = await func(...args);
            resolve(result);
          } catch (error) {
            reject(error);
          }
        }, delay);
      });
    };
  };