import { Title, Text } from "../../Component";
import React from "react";

export default function IntroductionSection() {
  return (
    <>
      {/* introduction section */}
      <div>
        <div className="tw-flex tw-h-[1022px] tw-items-center tw-justify-center tw-bg-[url(/public/assets/images/background_image1.webp)] tw-bg-cover tw-bg-no-repeat md:tw-h-auto md:tw-py-5">
          <div className="container tw-flex tw-justify-center md:tw-px-5">{/*adjusted the alignment */}
            <div className="tw-flex tw-w-full tw-flex-col tw-gap-[50px]">
              <div className="tw-flex tw-flex-col tw-items-center tw-gap-2.5 md:tw-mx-0">{/*adjusted the alignment */}
                <Text
                  as="p"
                  className="tw-text-[70px] tw-font-normal tw-text-primary_maroon md:tw-text-[48px] sm:tw-text-center sm:tw-text-[34px]"
                >    {/* adjusted the text size */}
                  What is Karishye
                </Text>
                <Title
                  size="texts"
                  as="h2"
                  className="tw-w-[800px] tw-text-[14px] tw-text-center tw-font-medium tw-leading-6 tw-tracking-[0.14px] tw-text-gray-900 sm:tw-w-[350px]"
                >{/* adjusted the text size */}
                  Karishye is a social impact company that aims to use
                  technology to bring all of us closer to the true form of
                  Sanatana Dharma. 
                  <br className="tw-hidden sm:tw-block"/> We are creating different products that can
                  help serve the vedic ecosystem and make it thriving and
                  self-sufficient
                </Title>
              </div>
              <div className="tw-flex tw-flex-col tw-items-center tw-gap-[72px] tw-border-[0.5px] tw-border-solid tw-border-orange-800 tw-bg-white-a700 tw-p-7 tw-shadow-md md:tw-gap-[54px] sm:tw-gap-9 sm:tw-p-[0.5rem]">
              <iframe
                  className="tw-h-[670px] tw-w-[1239px] tw-object-cover md:tw-h-[600px] md:tw-w-[600px] sm:tw-h-[200px] sm:tw-w-[300px]"  
                  src="https://www.youtube.com/embed/W9rCTfhQvVs"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="YouTube video"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
