import React, { useState, useRef, useEffect } from "react";
import { UserService } from "../../Services/UserService";
import { Button, Input, Title, ModalText, Img } from "../../Component";
import {
  MESSAGE_TYPES,
  DEFAULT_SELECTED_COUNTRY,
  COUNTRY_CODE,
  EVENT_CATEGORIES, EVENT_ACTIONS, EVENT_LABELS
} from "../../Constants/myDharmaContent";
import CountryDropdown from "../DropDown/index.js";
import { logEvent } from "../../Utils/Analytics";

export default function NotifySection({ isOpen, onClose, showSnackbar }) {
  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "",
    key: 0,
  });
  const [selectedCountry, setSelectedCountry] = useState(
    COUNTRY_CODE[DEFAULT_SELECTED_COUNTRY]
  );
  const [number, setNumber] = useState("");
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false); //state to handle the form submission
  const dropdownRef = useRef(null);

  const resetForm = () => {
    setFormData({ name: "", phoneNumber: "" });
    setNumber("");
    setError("");
    setIsSubmitting(false);
    setSelectedCountry(COUNTRY_CODE[DEFAULT_SELECTED_COUNTRY]);
  }; //function to reset the form fields

  const handleClose = () => {
    resetForm();
    onClose();
  };

  const handlePopupClick = () => {
    logEvent(EVENT_CATEGORIES.MY_DHARMA,EVENT_ACTIONS.MY_DHARMA_REGISTER_FORM, EVENT_LABELS.FORM_SUBMISSION);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    handlePopupClick(); 
    const updatedFormData = {
      ...formData,
      phoneNumber: `+${selectedCountry.phone}${number}`,
    };
    setError("");
    setIsSubmitting(true);

    // Validation
    if (!formData.name.trim() || !number.trim()) {
      setError("Please fill in all fields");
      setIsSubmitting(false);
      return;
    } //validation to check if the fields are empty

    try {
      const response = await UserService.registerUser(updatedFormData);
      if (response.status === 201) {
        const text_message = await response.data;
        if (text_message === "Your form has been submitted.") {
          showSnackbar(
            "Dear Seeker. Thank you for registering for My Dharma application. We will inform you once My Dharma is live",
            MESSAGE_TYPES.SUCCESS
          ); //changed the message
          onClose();
          setFormData({ name: "", phoneNumber: "" });
          setNumber("");
        } else {
          setError(text_message, MESSAGE_TYPES.WARNING);
        }
      } else {
        const serverError = await response.json();
        setError(serverError.message, MESSAGE_TYPES.ERROR); //changed the error names
      }
    } catch (err) {
      setError("An error occurred. Please try again.", MESSAGE_TYPES.ERROR);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === "Escape") onClose();
    };

    document.addEventListener("keydown", handleEscape);
    return () => document.removeEventListener("keydown", handleEscape);
  }, [onClose]);

  if (!isOpen) return null;

  return (
    <div
      className="modal fade signup-form show sm:tw-justify-center"
      role="dialog"
      style={{ display: "flex" }}
    >
      <div className="modal-dialog modal-dialog-centered tw-w-[509px] sm:tw-w-[300px]">
        <div className="modal-content padding">
          <div className="tw-flex tw-flex-col tw-items-center">
            <div className="container tw-flex tw-justify-center md:tw-px-5 tw-h-[450px] sm:tw-px-3">
              <div className="tw-relative tw-h-[450px] tw-w-[509px] tw-bg-white-a700 tw-p-6 sm:tw-p-5">
                <Img
                  src="/assets/images/close.webp"
                  loading="lazy"
                  onClick={handleClose}
                  className="tw-absolute tw-right-[0.5rem] tw-top-[1rem] tw-m-auto tw-w-[30px] sm:tw-top-[15px] tw-cursor-pointer tw-z-10"
                  alt="Close-Icon"
                /> {/*closes the modla when clicked on it*/}
                <div className="tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-top-0 tw-my-auto sm:tw-mt-[50px] tw-ml-3.5 tw-mr-auto tw-flex tw-h-max tw-flex-1 tw-flex-col tw-items-start tw-justify-center tw-gap-[40px] md:tw-ml-0">
                  <div className="tw-mr-3.5 tw-flex tw-flex-col tw-items-start tw-gap-5 tw-self-stretch md:tw-mr-0 sm:tw-gap-0">
                    <div className="tw-flex tw-justify-between">
                      <ModalText
                        size="text3xl"
                        as="p"
                        className="tw-font-playfairdisplay tw-text-center tw-text-[30px] tw-font-normal tw-text-primary_maroon sm:tw-text-center sm:tw-mt-0"
                      >
                        Enter your name and phone number
                      </ModalText>
                    </div>
                    <Img
                      src="/assets/images/seperator.webp"
                      alt="Login-Image"
                      loading="lazy"
                      className="tw-h-[6px] tw-w-[96%] tw-object-cover sm:tw-w-[100%] sm:tw-h-[4px]"
                    />
                    <form onSubmit={handleSubmit} className="tw-mt-4 tw-w-full">
                      <div className="tw-space-y-4">
                        {error && (
                          <div className="tw-text-red-500 tw-text-sm tw-mb-4 tw-px-2">
                            {error}
                          </div>
                        )}
                        <div>
                          <Title
                            size="texts"
                            as="h4"
                            className="tw-font-quicksand tw-text-[14px] tw-font-medium tw-text-black-900"
                          >
                            Name <span className="tw-text-red-500">*</span>
                          </Title>
                          <Input
                            size="sm"
                            shape="square"
                            name="name"
                            value={formData.name}
                            onChange={(e) =>
                              setFormData((prev) => ({
                                ...prev,
                                name: e.target.value,
                              }))
                            }
                            className="tw-mt-1 tw-w-full"
                          />
                        </div>

                        <div>
                          <Title
                            size="texts"
                            as="h4"
                            className="tw-font-quicksand tw-text-[14px] tw-font-medium tw-text-black-900"
                          >
                            Phone number{" "}
                            <span className="tw-text-red-500">*</span>
                          </Title>
                          <div
                            ref={dropdownRef}
                            className="tw-mt-1 tw-flex tw-items-center tw-border tw-border-gray-300 tw-rounded-md tw-w-[96%] tw-ml-[10px] tw-h-[42px]"
                          >
                            <div className="tw-h-[42px] tw-flex tw-items-center">
                              <CountryDropdown
                                selectedCountry={selectedCountry}
                                setSelectedCountry={setSelectedCountry}
                                COUNTRY_CODE={COUNTRY_CODE}
                              />
                            </div>{" "}
                            {/*changed according to the stylings */}
                            <input
                              type="tel"
                              value={number}
                              onChange={(e) => setNumber(e.target.value)}
                              className="tw-flex-1 tw-p-2 tw-h-[40px] tw-border-l tw-border-black tw-outline-none tw-w-[75%] sm:tw-mr-[10px]"
                              placeholder="Enter phone number"
                            />
                          </div>
                        </div>
                      </div>
                      <Button
                        type="submit"
                        variant="fill"
                        shape="square"
                        className="tw-mt-6 tw-w-[30%] tw-ml-[10px] sm:tw-w-auto"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? "Submitting..." : "Submit"}
                      </Button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}