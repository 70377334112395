import React, { useState, useEffect } from "react";
import "./Refund.scss";
import Footer from "../../Component/Footer/Footer";
import Whatsapp from "../../Component/Whatsapp/Whatsapp";
import Header from "../../Component/Header";
import Loader from "../../Component/Loader/Loader";
export default function Refund() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handleLoad = () => {
      setIsLoading(false);
    };

    // Check if document is already loaded
    if (document.readyState === 'complete') {
      handleLoad();
    } else {
      window.addEventListener('load', handleLoad);
    }

    return () => window.removeEventListener('load', handleLoad);
  }, []);
  return (
    <>
    {isLoading && <Loader fullScreen />}
    <Header/>
      <div className="refundSection">
        <div className="refundfstsec">
          <div className="centertext herosection-text">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <h1>Refund & Cancellation Policy</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="refundsndsec">
        <div className="container">
          <p>
            <strong>
              <u>INTRODUCTION</u>
            </strong>
          </p>
          <p>
            Thank You for choosing Karishye! We hope you are happy with your
            order/purchase. However, if You are not happy with Your
            purchase/order for any reason, You can initiate the refund request
            with us. Please see below detailed information on our refund policy.
          </p>
          <p>
            <strong>
              <u>General Terms & Conditions</u>
            </strong>
          </p>
          <p>
            You, as a User, may be entitled to claim a refund of the transaction
            price (as Your sole and exclusive remedy) in case You are not
            satisfied with the purchase/order/services for reason of
            non-availability of the priests or due to a Force Majeure event, or
            You do not receive the Delivery of products within the time period
            agreed, except in case of Force Majeure, or at the sole discretion
            of Karishye.
          </p>
          <p>
            Cancellations made 3 days or more in advance of the
            [Appointment/Service/Event] date will receive a 100% refund.
            Cancellations made within 3 days will receive a 50% refund.
            Cancellations made within 24 hours will not receive a refund.
          </p>
          <p>
            Refund requests are only accepted in the cases where Karishye
            approves the request of such refund requested by the User. Upon
            confirmation by Karishye the refund request shall be made within 30
            days (Stipulated time period).
          </p>
          <p>
            In case you do not raise a refund claim using Website/ App features
            within the Stipulated time period, this would make You ineligible
            for a refund. In such scenarios You may contact Karishye to
            determine the best course of action.
          </p>
          <p>
            You, as a Buyer, understand that the Refund Policy may not be
            available in full or in part for certain category of products and/or
            services and/or transactions and hence You may not be entitled to a
            refund in respect of the Transactions for those products and /or
            services.
          </p>
          <p>
            If Products returned are duly accepted by Karishye, the value of
            such Products, as originally paid by You will be refunded to You.
            Refund will only be processed to your registered Karishye account
            within two (2) business days.
          </p>
          <p>
            Refund shall be made in Indian Rupees and shall be equivalent to the
            transaction price received in Indian Rupees.
          </p>
          <br/>
          <p>
            Refund shall be subject to User complying with Terms and Conditions
            of this Website/App. Karishye shall communicate with you on the
            status of your refund request. Such communication shall be sent to
            your registered email address provided at the time of registration.
          </p>
          <p>
            <strong>Disputes via Chargeback</strong>
          </p>
          <br/>
          <p>
            Whenever a chargeback (CB) comes from a payment gateway/bank,
            following situations may arise:
          </p>
          <p>
            Item not received CB: – User hasn’t received the item. Refund will
            be created in accordance with the dispute policies<br/>Unauthorized CB:
            -User hasn’t made this particular transaction. Refund will be
            created in accordance with the dispute policies.
          </p>
          <br/>
          <p>
            <strong>
              <u>PROCESSING REFUND</u>
            </strong>
          </p>
          <p>
            Karishye shall initiate processing your refund, after internal
            verifications relating to non-performance of services,
            non-availability of priests etc.
          </p>
          <p>
            Your refund will be initiated by Karishye within two (2) business
            days of receiving the refund request from you. Such status for
            pending refund will reflect in your <strong><u>Karishye</u></strong> account within 24 hours
            of such refund initiated by Karishye.
          </p>
          <p>
            Please note sometimes due to certain constraints provided under
            Force Majeure, your refund process can be slow and, in such
            situations, Karishye will try its best to process your refund at the
            earliest.
          </p>
          <br/>
          <p>
            <strong>
              <u>QUERIES/CONCERNS</u>
            </strong>
          </p>
          <p>
            In case you have any queries/concerns with regards to the refund
              policy or on how to the process your refund, you can reach us at{" "}
            {process.env.REACT_APP_HELP_EMAIL} or can give a call on +91-{process.env.REACT_APP_QUERY_CONTACT} . Karishye will
            try their best to solve your queries/concerns.
          </p>
        </div>
        </div>
      </div>
      <Footer/>
      <Whatsapp/>
    </>
  );
}
