import React, { useState, useEffect }  from "react";
import "./TermsAndConditions.scss";
import Footer from "../../Component/Footer/Footer";
import Whatsapp from "../../Component/Whatsapp/Whatsapp";
import Header from "../../Component/Header";
import Loader from "../../Component/Loader/Loader";
export default function TermsAndConditions() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handleLoad = () => {
      setIsLoading(false);
    };

    // Check if document is already loaded
    if (document.readyState === 'complete') {
      handleLoad();
    } else {
      window.addEventListener('load', handleLoad);
    }

    // Cleanup
    return () => window.removeEventListener('load', handleLoad);
  }, []);
  return (
    <>
    {isLoading && <Loader fullScreen />}
      <Header />  
      <div className="termsSection">
        <div className="tandcfstsec">
          <div className="centertext herosection-text">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <h1>Terms & Conditions</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tandcsndsec">
          <div className="container">
            <p>
              1. INTRODUCTION
              <br />
              2. BUSINESS POLICY
              <br />
              3.ELIGIBILITY TO USE SITE
              <br />
              4. SIGNUP/ REGISTRATION
              <br />
              5. REPRESENTATIONS
              <br />
              6. USE OF MATERIALS
              <br />
              7. PURCHASES, GUARANTEES AND PAYMENT
              <br />
              8. SHIPPING AND DELIVERY OF THE PRODUCTS
              <br />
              9. CANCELLATION/ RE-SCHEDULING OF SERVICES/ REFUND
              <br />
              10. CUSTOMER CARE
              <br />
              11. PROHIBITED ACTIVITIES
              <br />
              12. INTELLECTUAL PROPERTY RIGHTS
              <br />
              13. CONTRIBUTION LICENSE
              <br />
              14. GUIDELINES FOR CONTRIBUTIONS
              <br />
              15. SOCIAL MEDIA
              <br />
              16. SUBMISSIONS
              <br />
              17. SITE MANAGEMENT
              <br />
              18. COPYRIGHT INFRINGEMENTS
              <br />
              19. TERM AND TERMINATION
              <br />
              20. MODIFICATIONS AND INTERRUPTIONS
              <br />
              21. GOVERNING LAW AND RESOLUTION
              <br />
              22. CORRECTIONS
              <br />
              23. NOTICES
              <br />
              24. LIMITATIONS OF LIABILITY
              <br />
              25. INDEMNIFICATION
              <br />
              26. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
              <br />
              27. FORCE MAJUERE
              <br />
              28. MISCELLANEOUS
            </p>
            <p>
              <strong>(1.) INTRODUCTION</strong>
            </p>
            <p>
              <strong>THESE TERMS AND CONDITIONS (“Terms of Use”) </strong>
              incorporated herein constitute a legally binding agreement made
              between Karishye Private Limited, a company incorporated under the
              laws of India having its registered office at H-802, Aparna
              Cyberzon, Nallagandla, Serilingampally, Hyderabad, Telangana,
              500019
              <strong>
                {" "}
                 (hereinafter referred to as “the Company” or “Karishye” or “we”
                or “our” or “us” and its successors and assigns)
              </strong>
              and whether personally or on behalf of an entity or any person
              <strong>
                 (hereinafter referred to as “you,” “your” or “yourself” or
                “User”),
              </strong>
               concerning your access to and use of the
              <strong> “Karishye.co” </strong>
              website as well as any other media form, media channel, mobile
              website related linked, or otherwise connected thereto
              <strong> (collectively, the “Site”). </strong>
              The Site provides an online facility for the purposes of providing
              a personalized experience relating to Vedic practices, calendar,
              rituals, prayers, astrological rituals, Numerology, Vaastu,
              Fengshui, KP, Prashna, Reiki, Healing, Panic Healing, Yoga&
              Meditation and other ancient sciences, in accordance with the
              Users faith, religion and beliefs. In addition to which the Site
              offers procurement of materials for the purposes of conducting
              such rituals, prayers etc.
              <strong> (“Services” or “Products”). </strong>
              In order to make the Site a secure environment for the usage of
              the Company’s services and products, all Users are required to
              accept and comply with these Terms and Conditions.
            </p>
            <p>
              BY ACCESSING, BROWSING OR OTHERWISE USING THE SITE YOU AGREE AND
              ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO BE BOUND
              BY ALL OF THESE TERMS OF USE, SO PLEASE READ THE TERMS CAREFULLY
              BEFORE USING OR ACCESSING ANY MATERIAL, INFORMATION OR SERVICES
              THROUGH THE SITE.
            </p>
            <p>
              IF ANY TIME YOU DO NOT AGREE WITH ALL OR ANY OF THESE TERMS AND
              CONDITIONS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SITE
              AND/OR SERVICES OFFERED HEREIN AND YOU MUST DISCONTINUE USE OF THE
              SITE IMMEDIATELY.
            </p>
            <p>
              <strong>(2.) BUSINESS POLICY</strong>
            </p>
            <p>
              The Company offers a platform that facilitates e-commerce business
              services
              <strong> (“Services”) </strong>
              for the purposes of providing a personalized experience relating
              to Vedic practices, calendar, rituals, prayers, astrological
              rituals, Numerology, Vaastu, Fengshui, KP, Prashna, Reiki,
              Healing, Panic Healing, Yoga& Meditation and other ancient
              sciences, in accordance with the Users faith, religion and beliefs
              to its end Users i.e.,
              <strong> (“Consumers”). </strong>
              The Terms of Use set out hereof, exclusively regulate the offer,
              transmission and acceptance of orders relating to Services on Site
              between the User of Site and Karishye.
            </p>
            <p>
              However, use of the Site, supplemental terms and conditions or
              documents that may be posted on the Site from time to time are
              hereby expressly incorporated herein by reference. Karishye
              reserves the right, in its sole discretion, to make changes or
              modifications to these Terms of Use at any time and for any
              reason. It is your responsibility to periodically review these
              Terms of Use to stay informed of updates. You will be subject to
              and will be deemed to have been made aware of and to have
              accepted, the changes in any revised Terms of Use by your
              continued use of the Site after the date such revised Terms of Use
              are posted.
            </p>
            <p>
              The information provided on the Site is not intended for
              distribution to or use by any person or entity in any jurisdiction
              or country where such distribution or use would be contrary to law
              or regulation or which would subject us to any registration
              requirement within such jurisdiction or country. Accordingly,
              those persons who choose to access the Site from other locations
              do so on their own initiative and are solely responsible for
              compliance with local laws, if and to the extent local laws are
              applicable.
            </p>
            <p>
              <strong>(3.) ELIGIBILITY TO USE SITE</strong>
            </p>
            <p>
              Services and facilities provided herein are not available for
              minors. It shall be your duty to ensure that you are eligible to
              enter into a contract as per your local laws. If you do not
              conform to the above qualification, you are not permitted to avail
              of the Services. You represent that you are of legal age to form a
              binding contract and are not a person barred from receiving
              Services under the applicable laws.
            </p>
            <p>
              Karishye reserves its rights to reject or grant the access to a
              new user or terminate the access of existing users, at any time
              without providing any reason.
            </p>
            <p>
              <em>
                In general, a person who has attained the age of (18) eighteen
                years is not considered a minor. However, we advise to check
                your local laws to comply with the provisions
              </em>
            </p>
            <p>
              <strong>(4.) SIGNUP/ REGISTRATION</strong>
            </p>
            <p>
              You may be required to Signup/Register on Site, which option is
              made available only to the person who can form legally binding
              contracts as per the applicable law. Persons who are “incompetent
              to contract” such as minors, un-discharged insolvents etc. are not
              eligible to register on the Site. As a minor if the User wishes to
              use the Site, the User shall be registered by a legal guardian or
              parents on the Site. If it comes to the knowledge of Karishye that
              the User registered on the Site via providing the personal
              information, is a minor, the User shall be removed from the Site
              immediately.
            </p>
            <p>
              The Signup/Registration process enables a User to access the
              Services that are rendered by Karishye. The User shall be required
              to Signup/Register on the Site by providing username, email
              address, name, cellphone number, password, et al. The User shall
              be responsible for maintaining confidentiality of his/her
              username/ display name and password and shall be responsible for
              all activities that occur under/ from his/her account. The User
              agrees and acknowledges that he/she will provide accurate,
              complete and updated information in order to Signup/Register on
              the Site to use the Services.
            </p>
            <p>
              The User further covenants that if you provide any information
              that is untrue, inaccurate, not current or incomplete or Karishye
              has reasonable grounds to suspect that such information is untrue,
              inaccurate, not current or incomplete, or not in line with the
              Terms of Use, Karishye shall have the right to indefinitely
              suspend/terminate/ block access of your account and access to the
              Site.
            </p>
            <p>
              <strong>(5.) REPRESENTATIONS</strong>
            </p>
            <p>
              <strong>(a) User Representations</strong>
            </p>
            <p>By using the Site, the User represents and warrants that:</p>
            <ul>
              <li>
                all registration information you submit will be true, accurate,
                current, and complete;
              </li>
              <li>
                you will maintain the accuracy of such information and promptly
                update such registration information as necessary;
              </li>
              <li>
                you have the legal capacity and you agree to comply with these
                Terms of Use;
              </li>
              <li>
                you are not a minor in the jurisdiction in which you reside;
              </li>
              <li>
                you will not access the Site through automated or non-human
                means, whether through a script or otherwise;
              </li>
              <li>
                you will not use the Site for any illegal or lawful purpose;
              </li>
              <li>
                your use of the Site will not violate any applicable laws or
                regulations.
              </li>
            </ul>
            <p>
              In all of the above conditions, Karishye has the right to suspend
              or terminate your account and refuse any and all current or future
              use of the Site.
            </p>
            <p>
              <strong>(b) Karishye Representation</strong>
            </p>
            <ul>
              <li>
                The Site, all the products and merchandise included on or
                otherwise made available to you through this Site are provided
                on “as is” basis without any representation or warranties except
                otherwise provided in writing.
              </li>
              <li>
                Notwithstanding anything contrary herein, subject to safety
                Karishye does not represent and warrant, the effectiveness,
                adequacy, accuracy, availability, prices, ratings, reviews, or
                legality of any of the information contained or displayed on the
                Site or offered through the Site.
              </li>
              <li>
                You understand and agree that the content of the Site does not
                contain or constitute representations to be reasonably relied
                upon, and you agree not to hold Karishye harmless from any
                errors, omissions, or misrepresentations contained within the
                Site’s content.
              </li>
            </ul>
            <p>
              <strong>(6.) USE OF MATERIALS</strong>
            </p>
            <p>
              Without prejudice as expressly indicated herein, Karishye hereby
              grants you a non-exclusive, revocable (upon notice from Karishye),
              non-transferable access to view, download and print product
              catalogues or any other materials available on the Site, subject
              to the following conditions:
            </p>
            <ul>
              <li>
                You may access and use the materials solely for own purposes and
                in accordance with the Terms of Use;
              </li>
              <li>
                You may not modify or alter product catalogues or any other
                materials in which Karishye’s rights subsists;
              </li>
              <li>
                You may not remove any text, data, copyright, et al contained in
                the product catalogues or any other materials;
              </li>
              <li>
                Any software that is available on the Site is the property of
                Karishye and you shall not use, download or install the
                software, unless otherwise expressly permitted by Karishye.
              </li>
            </ul>
            <p>
              <strong>(7.) PURCHASES, GUARANTEES AND PAYMENT</strong>
            </p>
            <p>
              You agree to provide the current, complete, and accurate
              information for all purchases made via the Site. You further agree
              to promptly update the account and payment information, including
              email address, payment method, and payment card expiration date,
              to enable Karishye to complete your transaction and contact you in
              case required. All the prices relating to materials necessary for
              performance of rituals, prayers (example- camphor, turmeric,
              honey, cow ghee etc. ) are exclusive of transportation and
              logistics charges. Applicable taxes will be added to the price of
              purchase as per the applicable law. Prices are subject to change.
              Check the final price of sale before placing the order on the
              Site. *Freight charges will be added to the final price (price
              after taxes) in case of air shipments.
            </p>
            <p>
              Karishye covenants that it does not sell second-hand products,
              flawed products or products of lower quality than the
              corresponding market standards. Notwithstanding anything mentioned
              herein to the contrary, Karishye covenants that the materials
              supplied for performance of rituals (example- camphor, turmeric,
              honey, cow ghee etc) prayers are not lab tested. Karishye request
              the User to read and understand the characteristics of Services
              and Products as shown on Site or in catalogue.
            </p>
            <p>We accept the following methods of payment:</p>
            <ul>
              <li>MasterCard (Credit / Debit card)</li>
              <li>Visa (Credit / Debit card)</li>
              <li>American Express (Credit / Debit card)</li>
              <li>Net banking</li>
            </ul>
            <p>
              You hereby undertake and agree to pay all/ complete charges in
              advance in lieu of your purchases and any applicable shipping
              fees, and you authorize us to charge your chosen payment provider
              for any such amounts upon placing your order. We reserve the right
              to correct any errors or mistakes in pricing, even if we have
              already requested or received the payment.
            </p>
            <p>
              <strong>
                (8.) RENDERING OF SERVICES AND SHIPPING AND DELIVERY OF THE
                PRODUCTS
              </strong>
            </p>
            <p>
              Upon receiving the order confirmation, Karishye shall notify you
              of your order (for illustrative purposes the email shall contain-
              date of ritual to be performed, the priest designated for the
              ritual, the material(s) to be utilized during such ritual etc.).
              Karishye will notify you on each stage commencing from inception
              to completion of the ritual
            </p>
            <p>
              Karishye will to its best efforts, make sure that the product
              reaches you within the specified time of delivery. However, in
              certain circumstances such as transport strikes / delay, floods,
              natural calamities, etc. the delivery may be subject to delay.
            </p>
            <p>
              <strong>
                (9.) CANCELLATION / RE-SCHEDULLING OF SERVICES / REFUND
              </strong>
            </p>
            <p>
              You cannot cancel or alter the order(s) once the payment is made,
              and the invoice is signed. In case of cancellation, you will not
              be entitled to any refund and Karishye reserves the right in its
              sole discretion to reimburse the amount paid by you in lieu of the
              purchase(s) made. In cases approved by Karishye, the below
              mentioned shall apply:
            </p>
            <p>
              Cancellations made 3 days or more in advance of the
              [Appointment/Service/Event] date will receive a 100% refund.
              Cancellations made within 3 days will receive a 50% refund.
              Cancellations made within 24 hours will not receive a refund.
            </p>
            <p>
              You may re-schedule services to be rendered, without an additional
              charge exclusive of shipping and freight charges, before two (2)
              days from date of performance of rituals, prayers etc.
            </p>
            <p>
              In the event that the request for re-scheduling does not comply
              with the terms and conditions set forth above, Karishye may in its
              sole discretion entertain the re-scheduling request, however the
              re-scheduling will be charged additionally and such charges shall
              be borne by you.
            </p>
            <p>
              <strong>(10.) CUSTOMER CARE</strong>
            </p>
            <p>
              You can contact our Customer Care for any information or help at{" "}
              {process.env.REACT_APP_HELP_EMAIL} Karishye shall to endeavor to provide the
              requisite support for closure of such query(ies) raised by you.
            </p>
            <p>
              <strong>(11.) PROHIBITED ACTIVITIES</strong>
            </p>
            <p>
              You may not access or use the Site for any purpose other than for
              which Karishye have provided access and made the Site available
              for. The Site may not be used in connection with any commercial
              endeavors except those that are specifically endorsed or approved
              by Karishye:
            </p>
            <ul>
              <li>
                Systematically retrieve data or other content from the Site to
                create or compile, directly or indirectly, a collection,
                compilation, database, or directory without written permission
                of Karishye.
              </li>
              <li>
                Make any unauthorized use of the Site, including collecting
                usernames and/or email addresses of users by electronic or other
                means for the purpose of sending unsolicited email, or creating
                user accounts by automated means or under false pretenses.
              </li>
              <li>
                Use a buying agent or purchasing agent to make purchases on the
                Site.
              </li>
              <li>
                Use the Site to advertise or offer to sell goods and services.
              </li>
              <li>
                Circumvent, disable, or otherwise interfere with
                security-related features of the Site, including features that
                prevent or restrict the use or copying of any content or enforce
                limitations on the use of the Site and/or the content contained
                therein.
              </li>
              <li>
                Engage in any automated use of the system, such as using scripts
                to send comments or messages, or using any data mining, robots,
                or similar data gathering and extraction tools.
              </li>
              <li>
                Make improper use of our support services or submit false
                reports of abuse or misconduct.
              </li>
              <li>
                Trick, defraud, or mislead us and other users, especially in any
                attempt to learn sensitive account information such as user
                passwords.
              </li>
              <li>Engage in unauthorized framing of or linking to the Site.</li>
              <li>
                Interfere with, disrupt, or create an undue burden on the Site
                or the networks or services connected to the Site.
              </li>
              <li>
                Attempt to impersonate another user or person or use the
                username of another user.
              </li>
              <li>Sell or otherwise transfer your profile.</li>
              <li>
                Use any information obtained from the Site in order to harass,
                abuse, or harm another person.
              </li>
              <li>
                Use the Site as part of any effort to compete with Karishye or
                otherwise use the Site and/or the content for any
                revenue-generating endeavor or commercial enterprise without the
                permission of Karishye.
              </li>
              <li>
                Decipher, decompile, disassemble, or reverse engineer any of the
                software, comprising or in any way forming part of the Site.
              </li>
              <li>
                Attempt to bypass any measures of the Site designed to prevent
                or restrict access to the Site, or any portion of the Site.
              </li>
              <li>
                Harass, annoy, intimidate, or threaten any of our employees or
                agents engaged in providing any portion of the Marketplace &
                Payment service Offerings to you.
              </li>
              <li>
                Delete the copyright or other proprietary rights from any
                content.
              </li>
              <li>
                Copy or adapt the Site’s software, including but not limited to
                Flash, PHP, HTML, JavaScript, or other code.
              </li>
              <li>
                Upload or transmit (or attempt to upload or to transmit)
                viruses, Trojan horses, or other material, including excessive
                use of capital letters and spamming (continuous posting of
                repetitive text), that interferes with any party’s uninterrupted
                use and enjoyment of the Site or modifies, impairs, disrupts,
                alters, or interferes with the use, features, functions,
                operation, etc.
              </li>
              <li>
                Upload or transmit (or attempt to upload or to transmit) any
                material that acts as a passive or active information collection
                or transmission mechanism, including without limitation, clear
                graphics, web bugs, cookies, or other similar devices (sometimes
                referred to as “spyware”).
              </li>
              <li>
                Except as may be the result of standard search engine or
                Internet browser usage, use, launch, develop, or distribute any
                automated system, including without limitation, any spider,
                robot, cheat utility, scraper, or offline reader that accesses
                the Site, or using or launching any unauthorized script or other
                software.
              </li>
              <li>
                Use the Site in a manner inconsistent with any applicable laws
                or regulations.
              </li>
            </ul>
            <p>
              <strong>(12.) INTELLECTUAL PROPERTY RIGHTS</strong>
            </p>
            <p>
              The Site and the processes are our proprietary property, including
              but not limited to all text, graphics, user interfaces, visual
              interfaces, software, database, code, design etc. (if any),
              (collectively, the<strong> “Content” </strong>) on the Site is owned
              and controlled by Karishye and the trademarks, service marks, and
              logos contained therein (collectively, the<strong> “Marks” </strong>
              ) are owned or controlled by Karishye or licensed to Karishye, and
              are protected by copyright, patent and trademark laws, and various
              other intellectual property rights.
            </p>
            <p>
              Karishye owns all intellectual property rights to the trademark
              “Karishye” and variants thereof, and the domain name, including,
              without limitation, any and all rights, title and interest in and
              to copyright, related rights, patents, utility models, designs,
              know-how, trade secrets and inventions (patent pending), goodwill,
              source code, meta tags, databases, text, content, graphics, icons,
              and hyperlinks.
            </p>
            <p>
              The Content and the Marks are provided on the Site “AS IS” for
              your information and is restricted to personal use. Except as
              expressly provided in the Terms of Use herein, no part of the Site
              and no Content or Marks may be copied, reproduced, aggregated,
              republished, uploaded, posted, publicly displayed, encoded,
              translated, transmitted, distributed, sold, licensed, or otherwise
              exploited for any commercial purpose whatsoever, without our
              express prior written permission.
            </p>
            <p>
              Provided that you are eligible to use the Site, you are granted a
              limited license to access and use the Site and to download or
              print a copy of any portion of the content to which you have
              properly gained access solely for your personal, non-commercial
              use.
            </p>
            <p>
              <strong>
                KARISHYE RESERVE ALL RIGHTS NOT EXPRESSLY GRANTED TO YOU IN AND
                TO THE SITE, THE CONTENT AND THE MARKS.
              </strong>
            </p>
            <p>
              <strong>(13.) CONTRIBUTION LICENSE</strong>
            </p>
            <p>
              By posting your contributions in the form of reviews/ comments/
              feedback to any part of the Site (collectively, the
              “Contributions”) or making Contributions accessible to the Site by
              linking your account from the Site to any of your social
              networking accounts, you automatically grant, and you represent
              and warrant that you have the right to grant, to Karishye an
              unrestricted, unlimited, irrevocable, perpetual, non-exclusive,
              transferable, royalty-free, fully-paid, worldwide right, and
              license to host, use, copy, reproduce, disclose, sell, resell,
              publish, broadcast, retitle, archive, store, cache, publicly
              perform, publicly display, reformat, translate, transmit, excerpt
              (in whole or in part), and distribute such Contributions
              (including, without limitation, your image and voice) for any
              purpose, commercial, advertising, or otherwise, and to prepare
              derivative works of, or incorporate into other works, such
              Contributions, and grant and authorize sublicenses of the
              foregoing. The use and distribution may occur in any media formats
              and through any media channels.
            </p>
            <p>
              This license will apply to any form, media, or technology now
              known or hereafter developed, and includes use of your name,
              company name, and franchise name, as applicable, and any of the
              trademarks, service marks, trade names, logos, and personal and
              commercial images you provide. You waive all moral rights in your
              Contributions, and you warrant that moral rights have not
              otherwise been asserted in your Contributions.
            </p>
            <p>
              Karishye has the right, in its sole and absolute discretion, (1)
              to edit, redact, or otherwise change any Contributions; (2) to
              re-categorize any Contributions to place them in more appropriate
              locations on the Site; and (3) to pre-screen or delete any
              Contributions at any time and for any reason, without notice.
              Karishye has no obligation to monitor your Contributions.
            </p>
            <p>
              <strong>(14.) GUIDELINES FOR CONTRIBUTIONS</strong>
            </p>
            <p>
              We may provide you areas on the Site to leave Contributions. When
              posting Contributions, you must comply with the following
              criteria:
            </p>
            <ul>
              <li>
                you should have firsthand experience with the person / entity;
              </li>
              <li>
                your Contributions should not contain offensive profanity,
                abusive, racist or hate language;
              </li>
              <li>
                your Contributions should not contain discriminatory references
                based on religion, race, gender, national origin, age, marital
                status, sexual orientation, or disability;
              </li>
              <li>
                your Contributions should not contain references to illegal
                activity;
              </li>
              <li>
                you should not be affiliated with competitors if posting
                negative Contributions;
              </li>
              <li>you may not post any false or misleading statements; and</li>
              <li>
                you may not organize a campaign encouraging others to post
                reviews / feedbacks, whether positive or negative.
              </li>
            </ul>
            <p>
              We may accept, reject, or remove Contributions in our sole
              discretion. We have absolutely no obligation to screen
              Contributions or to delete Contributions, even if anyone considers
              the Contributions objectionable or inaccurate. Contributions are
              not endorsed by us, and do not represent our opinions or the views
              of any of our affiliates or partners. We do not assume liability
              for any review or for any claims, liabilities, or losses resulting
              from any Contributions. By posting Contributions, you hereby grant
              to us a perpetual, non-exclusive, worldwide, royalty-free,
              assignable, license to reproduce, modify, translate, transmit by
              any means, display, perform, and/or distribute all content
              relating to Contribution.
            </p>
            <p>
              <strong>SOCIAL MEDIA</strong>
            </p>
            <p>
              As part of the functionality of the Site, you may link your
              account with online accounts you have with third-party service
              providers (each such account, a
              <strong> “Third-Party Account” </strong>) by either: (1) providing
              your Third-Party Account login information through the Site; or
              (2) allowing us to access your Third-Party Account, as is
              permitted under the applicable terms and conditions that govern
              your use of each Third-Party Account. You represent and warrant
              that you are entitled to disclose your Third-Party Account login
              information to us and/or grant us access to your Third-Party
              Account, without breach by you of any of the terms and conditions
              that govern your use of the applicable Third-Party Account, and
              without obligating us to pay any fees or subjecting us to any
              usage limitations imposed by the third-party service provider of
              the Third-Party Account. By granting us access to any Third-Party
              Accounts, you understand that (1) we may access, make available,
              and store (if applicable) any content that you have provided to
              and stored in your Third-Party Account (the “Social Network
              Content”) so that it is available on and through the Site via your
              account, including without limitation any friend lists and (2) we
              may submit to and receive from your Third-Party Account additional
              information to the extent you are notified when you link your
              account with the Third-Party Account. Depending on the Third-Party
              Accounts you choose and subject to the privacy settings that you
              have set in such Third-Party Accounts, personally identifiable
              information that you post to your Third-Party Accounts may be
              available on and through your account on the Site. Please note
              that if a Third-Party Account or associated service becomes
              unavailable or our access to such Third-Party Account is
              terminated by the third-party service provider, then Social
              Network Content may no longer be available on and through the
              Site. You will have the ability to disable the connection between
              your account on the Site and your Third-Party Accounts at any
              time.
            </p>
            <p>
              PLEASE NOTE THAT YOUR RELATIONSHIP WITH THE THIRD-PARTY SERVICE
              PROVIDERS ASSOCIATED WITH YOUR THIRD-PARTY ACCOUNTS IS GOVERNED
              SOLELY BY YOUR AGREEMENT(S) WITH SUCH THIRD-PARTY SERVICE
              PROVIDERS. Karishye make no effort to review any Social Network
              Content for any purpose, including but not limited to, for
              accuracy, legality, or non-infringement, and we are not
              responsible for any Social Network Content. You acknowledge and
              agree that we may access your email address book associated with a
              Third-Party Account and your contacts list stored on your mobile
              device or tablet computer solely for purposes of identifying and
              informing you of those contacts who have also registered to use
              the Site. You can deactivate the connection between the Site and
              your Third-Party Account by contacting us using the contact
              information below or through your account settings (if
              applicable). We will attempt to delete any information stored on
              our servers that was obtained through such Third-Party Account,
              except the username and profile picture that become associated
              with your account.
            </p>
            <p>
              <strong>(16.) SUBMISSIONS</strong>
            </p>
            <p>
              You acknowledge and agree that any questions, comments,
              suggestions, ideas, feedback, advice or other information
              regarding the Site<strong> (“Submissions“) </strong>
              provided by you to Karishye are non-confidential and shall become
              our sole property. We shall own exclusive rights, including all
              intellectual property rights, and shall be entitled to the
              unrestricted use and dissemination of these Submissions for any
              lawful purpose, commercial or otherwise, without providing any
              acknowledgment or compensation to you. You hereby waive all moral
              rights to any such Submissions, and you hereby warrant that any
              such Submissions are original with you or that you have the right
              to submit such Submissions. You agree there shall be no recourse
              against Karishye for any alleged or actual infringement or
              misappropriation of any proprietary right in your Submissions.
            </p>
            <p>
              <strong>(17.) SITE MANAGEMENT</strong>
            </p>
            <p>
              Karishye hold and reserve the right, but not the obligation, to:
            </p>
            <ul>
              <li>
                monitor the Site for violations of these Terms and Conditions;
              </li>
              <li>
                take appropriate legal action against anyone who, in our sole
                discretion, violates or intend to violate the law or these Terms
                and Conditions, including without limitation, reporting such
                user to law enforcement authorities;
              </li>
              <li>
                in our sole discretion and without limitation, refuse, restrict
                access to, limit the availability of, or disable (to the extent
                technologically feasible) any of your Contributions or any
                portion thereof;
              </li>
              <li>
                in our sole discretion and without limitation, notice, or
                liability, to remove from the Site or otherwise disable all
                files and content that are excessive in size or are in any way
                burdensome to our systems; and
              </li>
              <li>
                manage the Site in a manner designed to protect our rights and
                property and to facilitate the proper functioning of the Site.
              </li>
            </ul>
            <p>
              <strong>(18.) COPYRIGHT INFRINGEMENTS</strong>
            </p>
            <p>
              We respect the intellectual property rights of third parties. If
              you believe that any material available on or through the Site
              infringes upon any copyright you own or control, please notify
              Karishye using the contact information provided below (a{" "}
              <strong> “Notification”</strong>). A copy of your Notification will
              be sent to the person who posted or stored the material addressed
              in the Notification. Please be advised that pursuant to applicable
              laws you may be held liable for damages if you make material
              misrepresentations in a Notification. Thus, if you are not sure
              that material located on or linked to by the Site infringes your
              copyright, you should consider first contacting an attorney.
            </p>
            <p>
              <strong>(19.) TERM AND TERMINATION</strong>
            </p>
            <p>
              These Terms of Use shall remain in full force and effect while you
              use the Site. The Terms will continue to apply until terminated by
              either you or Karishye as set forth below.
            </p>
            <p>
              If you want to terminate your agreement with Karishye, you may do
              so by:
            </p>
            <ul>
              <li>not accessing the Site; or</li>
              <li>closing / deleting your accounts (if any)</li>
            </ul>
            <p>
              Without limiting any other provision of these terms and
              conditions, Karishye reserves the right to (at its discretion and
              at any time without notice or liability) deny access to use or
              terminate your account (including blocking certain ip addresses),
              for any cause or for no cause, including without limitation for
              breach of any representation, warranty, or covenant contained in
              these terms of use or of any applicable law or regulation if:-
            </p>
            <ul>
              <li>
                breach of any provisions of the Terms of Use, the Privacy Policy
                or any other terms, conditions, or policies that may be
                applicable to you;
              </li>
              <li>
                Karishye is required to do so due to any government notification
                or by law or becomes, unlawful or pursuant to a request by a
                regulatory authority;
              </li>
              <li>
                Karishye has elected to discontinue, with or without reason,
                access to the Site, the Services (or any part thereof)
                specifically to you; or
              </li>
              <li>For technical or any other reason.</li>
            </ul>
            <p>Termination or suspension may include:</p>
            <ul>
              <li>
                removal of access to all or portion of the offerings on the Site
                or with respect to the Services or any portion thereof;
              </li>
              <li>deletion of your account and the information thereof;</li>
              <li>
                If we terminate or suspend your account for any reason, you are
                prohibited from registering and creating a new account under
                your name, a fake or borrowed name, or the name of any third
                party, even if you may be acting on behalf of the third party;
              </li>
              <li>
                In addition to terminating or suspending your account, Karishye
                reserves the right to take appropriate legal action, including
                without limitation pursuing civil, criminal, and injunctive
                redress.
              </li>
            </ul>
            <p>Your sole right to terminate these Terms of Use is by deleting your Account or not accessing the Site.</p>
            <p>
              <strong>(20.) MODIFICATIONS AND INTERRUPTIONS</strong>
            </p>
            <ul>
              <li>
                Karishye reserves the right to change, modify, alter, or remove
                the contents of the Site at any time or for any reason at its
                sole discretion without notice. However, we have no obligation
                to update any information on our Site.
              </li>
              <li>
                Karishye also reserves the right to modify or discontinue all or
                part of the Site without notice at any time.
              </li>
              <li>
                Karishye will not be liable to you or any third party for any
                modification, price change, suspension, or discontinuance of the
                Site.
              </li>
              <li>
                Karishye cannot guarantee the Site will be available at all
                times as the Site may experience software or other problems
                including all the technical issues. We may also need to perform
                maintenance services of the Site, resulting in interruptions,
                delays, or errors.
              </li>
              <li>
                You agree that Karishye has no liability whatsoever for any
                loss, damage, or inconvenience caused by your inability to
                access or use the Site during any downtime or discontinuance of
                the Site.
              </li>
              <li>
                Nothing in these Terms of Use will be construed to obligate
                Karishye to maintain and support the Site or to supply any
                corrections, updates, or releases in connection therewith.
              </li>
            </ul>
            <p><strong>(21.) GOVERNING LAW AND DISPUTE RESOLUTION</strong></p>
            <p>All disputes, differences relating to these Terms of Use, Karishye services, its enforceability or its termination under these Terms of Use and your use of the Site or any interpretation thereof are governed by and will be construed under the laws of India, without regard to its conflict of law principles, thereof, unless prohibited by law.</p>
            <p>Any legal action of whatever nature brought by either you or Karishye (collectively, the<strong> “Parties” </strong>and individually, a<strong> “Party” </strong>) shall be commenced or prosecuted in the courts located in Hyderabad, and the Parties hereby consent to, and waive all defenses of lack of personal jurisdiction and forum non-convenience with respect to venue and jurisdiction in such state court.</p>
            <p><strong>THIS CLAUSE SHALL SURVIVE THE TERMINATION OR EXPIRY OF THIS AGREEMENT.</strong></p>
            <p><strong>(22.) CORRECTIONS</strong></p>
            <p>There may be information on the Site that contains typographical errors, inaccuracies, omissions or mistakes, including descriptions, pricing, availability, and various other information. We reserve the right to correct any errors, inaccuracies, omissions or mistakes and to change, modify, alter or update the information on the Site at any time, without prior notice.</p>
            <p><strong>(23.) NOTICES</strong></p>
            <p>Any notices, requests, complaints, queries, demands and any other communications required or permitted to submit in relation to above or any other circumstances that arise due to the use of the Site or under these Terms of Use shall be communicated at {process.env.REACT_APP_HELP_EMAIL}</p>
            <p>The User understands that there may be instances of difficulty in accessing or receiving such notices. Karishye shall not responsible if any email to the Users notices, requests, complaints, queries or demands are delayed or not delivered or if the User does not receive an email/ reply.</p>
            <p><strong>(24.) LIMITATIONS OF LIABILITY</strong></p>
            <p>IN NO EVENT SHALL KARISHYE OR ITS DIRECTORS, EMPLOYEES, AGENTS, AFFILAIATES, OFFICERS OR VENDORS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES OR EXPENSES ARISING FROM YOUR USE OF THE SITE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</p>
            <p>NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO US.</p>
            <p>FURTHER, KARISHYE SHALL NOT BE LIABLE TO YOU FOR ANY TEMPORARY DISABLEMENT, PERMANENT DISCONTINUANCE OF THE SERVICES BY KARISHYE OR FOR ANY CONSEQUENCES RESULTING FROM SUCH ACTIONS. CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.</p>
            <p>NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, KARISHYE SHALL NOT BEAR ANY LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, RELATING TO PERFORMANCE OF SERVICES AND SUPPLY OF PRODUCTS, BY US TO YOU. KARISHYE SHALL NOT BE HELD LIABLE FOR INCLUDING BUT NOT LIMITED TO HURTING RELIGIOUS SENTIMENTS/ IDEOLOGIES/SANATANA DHARMA. THE SERVICES AND PRODUCTS ARE PROVIDED BY KARISHYE AND ARE CONSUMED BY USERS UPON THE USER’S PERSONAL DISCRETION. KARISHYE DOES NOT PROVIDE ANY EXPRESS OR IMPLIED GAURANTEE OR WARRANTY RELATING TO BENEFITS OF PERFORMACE OF SUCH SERVICES AND PRODUCTS. KARISHYE MAKES NO REPRESENTATION OR WARRANTY, EXPRESS OR IMPLIED, AT LAW OR IN EQUITY, IN RESPECT OF ANY MATTER RELATING TO THE COMPANY, INCLUDING, WITHOUT LIMITATION, THE SERVICES, PRODCUTS, LIABILITIES, OPERATIONS OR PROSPECTS OF THE COMPANY OR IN RESPECT OF THE TRANSACTIONS CONTEMPLATED HEREIN, AND ANY SUCH OTHER REPRESENTATIONS OR WARRANTIES ARE HEREBY EXPRESSLY DISCLAIMED BY KARISHYE.</p>
            <p><strong>(25.) INDEMNIFICATION</strong></p>
            <p>You agree to indemnify, and hold Karishye harmless, including subsidiaries, affiliates, and all of our respective officers, vendors, agents, partners, and employees, from and against any and all losses, liabilities, claims, damages (actual and consequential), expenses or demands, including reasonable attorneys’ fees and expenses, made by any third party due to or arising out of  any breach of the representations and warranties set forth in these Terms of Use, or violation of the rights of any third party, including but not limited to intellectual property rights; or any harmful act toward any other User of the Site.</p>
            <p><strong>(26.) ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</strong></p>
            <p>Visiting the Site, sending us emails, and completing online forms constitute electronic communications. You consent to receive electronic communications, and you agree that all agreements, notices, disclosures, and other communications we provide to you electronically, via email and on the Site, satisfy any legal requirement that such communication be in writing.</p>
            <p>YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SITE.</p>
            <p>You hereby waive any rights or requirements under any statutes, regulations, rules, ordinances, or other laws in any jurisdiction which require an original signature or delivery or retention of non-electronic records, or to payments or the granting of credits by any means other than electronic means.</p>
            <p><strong>(27.) FORCE MAJEURE</strong></p>
            <p>Karishye shall not be considered to be in default or breach of this agreement, and shall be excused from performance or liability for damages to any other party, if and to the extent it shall be delayed in or prevented from performing or carrying out any of the provisions of this agreement, arising out of or from any act, omission, or circumstance by or in consequence of any act of God, labor disturbance, sabotage, failure of suppliers of materials, act of the public enemy, war, invasion, insurrection, riot, fire, storm, flood, ice, earthquake, explosion, epidemic, breakage or accident to machinery or equipment (including virtual video failures) or any other cause or causes beyond Karishye’s reasonable control, including any curtailment, order, regulation, or restriction imposed by governmental, military or lawfully established civilian authorities. In case of Karishye claiming a Force Majeure event, it shall use reasonable diligence to remove the condition that prevents performance and shall not be entitled to suspend performance of its obligations in any greater scope or for any longer duration than is required by the Force Majeure event. Karishye shall use its best efforts to mitigate the effects of such Force Majeure event, remedy its inability to perform, and resume full performance of its obligations hereunder.</p>
            <p><strong>(28.) MISCELLANEOUS</strong></p>
            <p>a) Karishye may assign, in whole or in part, its rights and obligations to a third party. Karishye shall not be responsible or liable for any loss, damage, delay, or failure to act caused by any condition beyond our reasonable control.</p>
            <p>b) If any provision or part of a provision, of these Terms of Use is determined to be unlawful or void that provision or part of the provision is deemed severable from these Terms of Use and does not affect the validity and enforceability of any remaining provisions.</p>
            <p>c) Karishye and you shall at all times and for purposes be deemed to be independent contractors and neither Party, nor either Party’s employees, representatives, subcontractors or agents, shall have the right or power to bind the other Party. This Agreement shall not itself create or be deemed to create a joint venture, partnership or similar association between the Parties or either Party’s employees, subcontractors or agents.</p>
            <p>d) There is no partnership, employment, agency relationship or similar association between you and Karishye as a result of these Terms of Use.</p>
            <p>e) Karishye shall not be required to perform under these Terms of Use as a result of occurrence of any Force Majeure events like acts of God, fire, wars, civil unrest, change in laws, rules and regulations or any other events affecting the performance of Karishye.</p>
            <p>f) Karishye and you agreed that the arrangement set out by these Terms and Conditions are on a non-exclusive basis.</p>
            <p>g) These Terms of Use constitute the entire agreement and understanding of the Parties (Karishye and You).</p>
            <p>h) You agree that these Terms of Use will not be construed against Karishye by virtue of having drafted them. You hereby waive any and all defenses you may have based on the electronic form of these Terms of Use and the lack of signing by the parties hereto to execute these Terms of Use.</p>
            <p><strong>I AGREE TO THE ABOVE TERMS AND CONDITIONS</strong></p>
            </div>
          </div>
        </div>
      <Footer />
      <Whatsapp/>
    </>
  );
}
