import React, { useState, useEffect } from 'react';
import './Loader.scss';

const Loader = ({ fullScreen }) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = "/assets/images/logo-icon-rozo.png";
    img.onload = () => setIsImageLoaded(true);
  }, []);

  return (
    <div className={`loader-wrapper ${fullScreen ? 'fullscreen' : ''}`}>
      {isImageLoaded && (
        <div className="loader-container">
          <div className="loader">
            <img 
              src="/assets/images/logo-icon-rozo.png" 
              alt="Loading-Icon" 
              className="loader-image"
            />
          </div>
          <p className="loading-text">Loading...</p>
        </div>
      )}
    </div>
  );
};

export default Loader;