import React, { useEffect, useState } from "react";
import "./MyProfile.scss";
import Whatsapp from "../../Component/Whatsapp/Whatsapp";
import Footer from "../../Component/Footer/Footer";
import Header from "../../Component/Header";
import { UserService } from "../../Services/index";
import Loader from "../../Component/Loader/Loader";
function MyProfile() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState({
    id: "",
    name: "",
    surname: "",
    emailId: "",
    phoneNumber: "",
    dateOfBirth: "",
    gothram: "",
    gender: "",
    nakshatram: "",
    addressLine1: "",
    addressLine2: "",
    country: "",
    state: "",
    city: "",
    pincode: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const userId = localStorage.getItem("userId");
  const submitFormUserDetails = async (event) => {
    event.preventDefault();
    const payload = {
      data: {
        ...formData,
      },
    };
    
    try {
      const response = await UserService.updateUser(payload);
       localStorage.setItem("user", formData.name);
      if (response.ok) {
        setIsModalVisible(true);
        setTimeout(() => {
          setIsModalVisible(false);
        }, 2000);
      } else {
        console.error("Error in submitting form", response.statusText);
      }
    } catch (error) {
      console.error("Error submitting form", error);
    }
  };

  useEffect(() => {
    const fetchUser = async () => {
      try{
      const users = await UserService.getUserDetails();
      const user = Object.values(users).find((user) => user.id === userId);

      if (user) {
        setFormData({
          id: user.id,
          name: user.name || "",
          surname: user.surname || "",
          emailId: user.emailId || "",
          phoneNumber: user.phoneNumber || "",
          dateOfBirth: user.dateOfBirth || "",
          gothram: user.gothram || "",
          gender: user.gender || "",
          nakshatram: user.nakshatram || "",
          addressLine1: user.addressLine1 || "",
          addressLine2: user.addressLine2 || "",
          country: user.country || "",
          state: user.state || "",
          city: user.city || "",
          pincode: user.pincode || "",
        });
      }}catch (error) {
        console.error("Error fetching user details", error);
      } finally {
        setIsLoading(false); // Hide loader once data is fetched
      }
    };

    if (userId) {
      fetchUser();
    }
  }, [userId]);

  const profileContent = (
    <section className={`karishye-form ${isModalVisible ? "blur" : ""}`}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="booking-form">
              <h2 className="contact-button">My Profile</h2>
              <form onSubmit={submitFormUserDetails}>
                <div className="accordion" id="formkarishye">
                  <div className="accordion-item">
                    <div className="accordion-collapse">
                      <div className="accordion-body">
                        <div className="form-group mb-3 max-width-l">
                          <label className="labeltitle" htmlFor="first_name">
                            First Name <span className="erroricon">*</span>
                          </label>
                          <input
                            type="text"
                            value={formData.name}
                            id="first_name"
                            onChange={handleChange}
                            name="name"
                            className="form-control int-fill"
                          />
                        </div>
                        <div className="form-group mb-3 max-width-r">
                          <label className="labeltitle" htmlFor="last_name">
                            Last Name <span className="erroricon">*</span>
                          </label>
                          <input
                            type="text"
                            id="last_name"
                            onChange={handleChange}
                            value={formData.surname}
                            name="surname"
                            className="form-control int-fill"
                          />
                        </div>
                        <div className="form-group mb-3 max-width-l">
                          <label className="labeltitle" htmlFor="email">
                            Email ID <span className="erroricon">*</span>
                          </label>
                          <input
                            type="email"
                            onChange={handleChange}
                            id="email"
                            value={formData.emailId}
                            name="emailId"
                            className="form-control int-fill"
                          />
                        </div>
                        <div className="form-group mb-3 max-width-r">
                          <label className="labeltitle" htmlFor="gender">
                            Gender <span className="erroricon">*</span>
                          </label>
                          <select
                            name="gender"
                            className="form-select int-fill"
                            onChange={handleChange}
                            value={formData.gender}
                          >
                            <option value="">Please Select</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                          </select>
                        </div>
                        <div className="form-group mb-3 max-width-l">
                            <label
                              className="labeltitle"
                              htmlFor="phone_number"
                            >
                              Phone Number <span className="erroricon">*</span>
                            </label>
                            <input
                              type="text"
                              onChange={handleChange}
                              id="phone_number"
                              name="phoneNumber"
                              value={formData.phoneNumber}
                              className="form-control int-fill"
                              required
                              disabled
                            />
                          </div>
                          <div className="form-group mb-3 max-width-r">
                            <label
                              className="labeltitle"
                              htmlFor="date_of_birth"
                            >
                              Date of Birth
                            </label>
                            <input
                              type="date"
                              onChange={handleChange}
                              name="dateOfBirth"
                              value={formData.dateOfBirth ? new Date(formData.dateOfBirth).toLocaleDateString('en-CA') : ''}
                              data-date-inline-picker="true"
                              className="form-group int-fill datepicker hasDatepicker"
                              placeholder="yyyy-mm-dd"
                            />
                          </div>
                          <div className="form-group mb-3 max-width-l">
                            <label className="labeltitle" htmlFor="gotra">
                              Gothra
                            </label>
                            <input
                              type="text"
                              id="gothram"
                              onChange={handleChange}
                              name="gothram"
                              className="form-control int-fill"
                              value={formData.gothram}
                            />
                          </div>
                          <div className="form-group mb-3 max-width-r outer-nakshatram">
                            <label className="labeltitle" htmlFor="nakshatram">
                              Nakshatra
                            </label>
                            <select
                              name="nakshatram"
                              className="form-select int-fill nakshatram email_nakshatram"
                              onChange={handleChange}
                              value={formData.nakshatram}
                            >
                              <option value="">Please Select</option>
                              <option value="1">Ashvini</option>
                              <option value="2">Bharani</option>
                              <option value="3">Krritika</option>
                              <option value="4">Rohini</option>
                              <option value="5">Mrgasira</option>
                              <option value="6">Arudra</option>
                              <option value="7">Punarvasu</option>
                              <option value="8">Pushya</option>
                              <option value="9">Ashlesa</option>
                              <option value="10">Magha</option>
                              <option value="11">Purva Phalgun</option>
                              <option value="12">Uttara Phalgun</option>
                              <option value="13">Hasta</option>
                              <option value="14">Chitra</option>
                              <option value="15">Swathi</option>
                              <option value="16">Vishaka</option>
                              <option value="17">Anuradha</option>
                              <option value="18">Jyeshta</option>
                              <option value="19">Mula</option>
                              <option value="20">Purva Ashada</option>
                              <option value="21">Uttara Ashada</option>
                              <option value="22">Abhijit</option>
                              <option value="23">Sravana</option>
                              <option value="24">Dhanishta</option>
                              <option value="25">Satabhisha</option>
                              <option value="26">Purva Bhadrapada</option>
                              <option value="27">Uttara Bhadrapada</option>
                              <option value="28">Revati</option>
                            </select>
                          </div>
                          <div className="form-group mb-3">
                            <label className="labeltitle" htmlFor="address1">
                              Address 1
                            </label>
                            <textarea
                              name="addressLine1"
                              onChange={handleChange}
                              className="form-control int-fill"
                              value={formData.addressLine1}
                            ></textarea>
                          </div>
                          <div className="form-group mb-3">
                            <label className="labeltitle" htmlFor="address2">
                              Address 2
                            </label>
                            <textarea
                              name="addressLine2"
                              onChange={handleChange}
                              className="form-control int-fill"
                              value={formData.addressLine2}
                            ></textarea>
                          </div>
                          <div className="form-group mb-3 max-width-l">
                            <label className="labeltitle" htmlFor="country">
                              Country<span className="erroricon">*</span>
                            </label>
                            <input
                              type="text"
                              name="country"
                              onChange={handleChange}
                              className="form-control int-fill"
                              value={formData.country}
                            />
                          </div>
                          <div className="form-group mb-3 max-width-r">
                            <label className="labeltitle" htmlFor="state">
                              State
                            </label>
                            <input
                              type="text"
                              name="state"
                              onChange={handleChange}
                              className="form-control int-fill"
                              value={formData.state}
                            />
                          </div>
                          <div className="form-group mb-3 max-width-l">
                            <label className="labeltitle" htmlFor="city">
                              City<span className="erroricon">*</span>
                            </label>
                            <input
                              type="text"
                              onChange={handleChange}
                              name="city"
                              className="form-control int-fill"
                              value={formData.city}
                            />
                          </div>
                          <div className="form-group mb-3 max-width-r">
                            <label className="labeltitle" htmlFor="pincode">
                              Pincode
                            </label>
                            <input
                              type="text"
                              onChange={handleChange}
                              name="pincode"
                              className="form-control int-fill"
                              value={formData.pincode}
                            />
                          </div>
                        <div className="btn-box">
                          <button
                            type="submit"
                            id="submitformdata"
                            className="bookingsubmitbut"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );

  return (
    <>
      <Header />
      {isLoading ? <Loader /> : profileContent}
      {isModalVisible && (
        <div className="modal-container">
          <div
            className="modal fade show"
            id="myprofile-aftersubmit"
            tabIndex="-1"
            aria-labelledby="sendopt"
            style={{ display: "block" }}
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5
                    className="modal-title"
                    id="sendopt"
                    style={{ justifyContent: "center", margin: "0 auto" }}
                  >
                    My profile details updated successfully
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />
      <Whatsapp />
    </>
  );
}

export default MyProfile;
