import { Text, Img} from "../../Component";      //imported the NotifySection component
import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function DharmaSection() {

  return (
    <>
      <div className="container tw-flex sm:tw-flex-col md:tw-items-center tw-items-center tw-justify-between sm:tw-align-center">     {/*adjusted the alignment */}
        <div className="tw-flex tw-flex-col md:tw-w-[80%] sm:tw-w-[90%] tw-items-center tw-gap-[50px] md:tw-gap-[72px] sm:tw-gap-12 lg:tw-flex-1">
          <div className="tw-flex tw-flex-col tw-gap-10 sm:tw-gap-0 md:tw-w-full sm:tw-text-center">
            <div className="tw-flex tw-flex-col tw-items-start tw-justify-center tw-gap-2.5 md:tw-items-center"> {/*changed the alignment */}
              <Text
                as="p"
                className="tw-w-[671px] tw-text-[70px] tw-font-normal tw-leading-[96px] tw-text-primary_maroon md:tw-text-[30px] md:tw-text-center md:tw-leading-normal md:tw-w-[auto] md:tw-font-500"
              >
                Dhryatheva Jainairithi Dharmam
              </Text>
              <Img
                src="/assets/images/mydharma_seperator.webp"
                alt="Seperator-Icon"
                className="tw-w-[671px] sm:tw-w-[220px] sm:tw-h-[10px] sm:tw-hidden"
                loading="lazy"
              /> 
              <Img
                src="/assets/images/dharma_seperator_mobile.webp"
                alt="Seperator-Icon"
                className="tw-hidden tw-w-[671px] sm:tw-w-[260px] sm:tw-h-[10px] sm:tw-block"
                loading="lazy"
              />
              <h2
                size="texts"
                className="tw-text-[30px] tw-w-full tw-font-500 tw-tracking-[0.14px] tw-text-[#5A1740] tw-font-playfairdisplay sm:tw-text-[18px] md:tw-text-[24px] md:tw-text-center sm:tw-w-[230px]"  //adjusted the text size
              >
                “What we follow is Dharma. Not what we know”
              </h2>
            </div>
          </div>
        </div>
        <div className="tw-flex tw-flex-col">    {/*adjusted the alignment */}
          <Img
            src="/assets/images/bell-Image.webp"
            alt="Bell-Icon"
            className="tw-h-[700px] tw-w-[500px] tw-object-contain md:tw-h-[500px] md:tw-w-[350px] sm:tw-h-[310px] sm:tw-w-[236px]"
            loading="lazy"
          />
        </div>
      </div>
    </>
  );
}