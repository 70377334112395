import { Button, Text, Img } from "../../Component";     //changed the file name
import React, { useState } from "react";
import DetailsModal from "../../Component/Modal/DetailsModal";

export default function BannerSection() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleExploreClick = () => {     //function to open the modal
    setIsModalOpen(true);    //set the modal to open
  };
  return (
    <>
      <div className="tw-flex tw-items-center tw-justify-center tw-bg-[url('/public/assets/images/background_image1.webp')] tw-bg-cover tw-bg-no-repeat tw-pb-12 md:tw-h-auto md:tw-py-5">
        <div className="tw-container tw-flex tw-justify-center md:tw-px-5 sm:tw-px-0"> {/*adjusted the alignments */}
          <div className="tw-flex tw-w-[98%] tw-items-center tw-justify-center  tw-border-solid tw-border-[#DF7900] tw-bg-[url('/public/assets/images/img_contact_notified.png')] tw-bg-cover tw-bg-no-repeat tw-px-10 tw-shadow-md md:tw-h-auto sm:tw-px-5">
            <div className="tw-flex tw-flex-1 tw-items-center tw-gap-[7px] md:tw-self-stretch">
              <div className="tw-relative  tw-w-[16%] tw-content-center md:tw-h-auto md:tw-w-[50%]">
                <Img
                  src="/assets/images/img_icon_page_1.png"
                  alt="Page-Icon"
                  className="tw-mx-auto tw-w-full tw-object-cover"
                  loading="lazy"
                />
                <Img
                  src="/assets/images/img_group_1000007036.png"
                  alt="Group-Image"
                  className="tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-top-0 tw-m-auto tw-h-[64px] tw-w-[36%] tw-object-contain"
                  loading="lazy"
                />
              </div>
              <Text
                size="textxl"
                as="p"
                className="tw-text-[36px] tw-font-playfairdisplay tw-font-[600] tw-text-primary_maroon md:tw-text-34px sm:tw-leading-[0] sm:tw-mb-[0]"
              >
                <span className="tw-font-[400] tw-font-playfairdisplay sm:tw-text-[14px]">
                  Know about&nbsp;
                </span>
                <span className="tw-font-[500] tw-font-playfairdisplay sm:tw-text-[14px]">
                  Karishye values and beliefs
                </span>
              </Text>
            </div>
            <Button
              variant="fill"
              shape="square"
              className="tw-min-w-[132px] tw-px-[34px] tw-font-quicksand tw-font-medium tw-tracking-[0.16px] sm:tw-px-[0] sm:tw-min-w-[71px]"
              onClick={handleExploreClick}
            >
              Explore
            </Button>
          </div>
        </div>
      </div>
      <DetailsModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
}
