import axiosInstance from './AxiosInstance';
import {debounce} from "../Utils/Debounce";

export const PaymentService = {
    initiatePayment: debounce (async (paymentData) => {
          const response = await axiosInstance.post('/orders/payment', paymentData);
          return response.data;
      },300),
  
      processTransaction: debounce (async (transactionData) => {
        const response = await axiosInstance.post('/transaction', transactionData);
        return response.data;
      },300),
    
      generateInvoice: debounce (async (invoiceData) => {
        const response = await axiosInstance.post('/users/generate-invoice', invoiceData, {
          responseType: 'blob'
        });
        return response.data;
      },300),
    
      generateEstimate: debounce(async (estimateData) => {
        const response = await axiosInstance.post('/users/generate-estimate', {
          data: estimateData
        }, {
          responseType: 'blob'
        });
        return response.data;
      },300),
  };