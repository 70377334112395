/* eslint-disable react/display-name */
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Title, Img, Text, SearchBar } from "../../Component";
import { TabList, Tab } from "react-tabs";
import { PUJA_CATEGORIES } from "../../Constants/myDharmaContent";
import { PujaService } from "../../Services/PujaService";
import CardsSection from "./CardsSection";
import BannerSection from "./BannerSection";   //changed the file name

// Extracted helper functions
const isInvalidPuja = (puja, type) => {
  const isPopularModel = type === "all-pujas" 
    ? puja?.pujamodels?.[0]?.isPopularModel 
    : puja?.pujas?.pujamodels?.[0]?.isPopularModel;
  
  return isPopularModel === false;
};

const getProcedures = (puja, type) => {
  return type === "all-pujas" 
    ? puja?.pujamodels[0]?.procedures 
    : puja?.pujas?.pujamodels[0]?.procedures;
};

const mapPujasToDisplayFormat = (pujas, active) => {
  return pujas.map(puja => ({
    ...puja,
    name: active !== "All Pujas" ? puja.pujas.name : puja.name,
    url_name: active !== "All Pujas" ? puja.pujas.url_name : puja.url_name,
    id: active !== "All Pujas" ? puja.pujaId : puja.id,
  }));
};

export default function CategorySection() {
  const [state, setState] = useState({
    showContactUs: false,
    isDropdownOpen: false,
    filter: "",
    active: localStorage.getItem("tab") || "All Pujas",
    allPujas: [],
    lifeEventList: [],
    occEventList: [],
  });

  // Centralized state update function
  const updateState = useCallback((newState) => {
    setState(prevState => ({ ...prevState, ...newState }));
  }, []);

  // Fetch pujas and initialize data
  const fetchPujasData = useCallback(async () => {
    try {
      const pujasResponse = await PujaService.getAllPujas();
      const pujaListData = pujasResponse?.data?.data?.[1]?.subCategories;
      const lifeEventData = pujasResponse?.data?.data?.[0]?.subCategories[0]?.pujasubcategorymappings;    //changed the data fetching logic to get the required data with same names as in the response
      const selectedOcc = Array.isArray(pujaListData) ? pujaListData.find((i) => {
        return i?.name?.toLowerCase() === state?.active?.toLowerCase(); //changed the condition to compare the names in lowercase
      }) : undefined;
      updateState({
        lifeEventList: lifeEventData,
        occEventList: selectedOcc ? selectedOcc.pujasubcategorymappings : [],
      });
    } catch (err) {
      console.error("Error fetching pujas:", err);
    }
  }, [state.active, updateState]);

  // Fetch all pujas list
  const fetchAllPujasList = useCallback(async () => {
    try {
      const response = await PujaService.getPujaList();
      updateState({ allPujas: response });
    } catch (err) {
      console.error("Error fetching all-pujas:", err);
    }
  }, [updateState]);

  // Handle tab change
  const changeTab = useCallback((type) => {
    updateState({ 
      active: type,
      filter: "",
    });
    localStorage.setItem("tab", type);
  }, [updateState]);

  // Redirect handler
  const redirection = useCallback((item, type) => {
    if (!item || item.length === 0) return;

    if (isInvalidPuja(item, type)) {
      updateState({ showContactUs: true });
      return;
    }

    const procedures = getProcedures(item, type);

    if (procedures) {
      localStorage.setItem("ToBooking", false);
      window.open(`poojas/telugu/${item?.url_name}`, "_self");
    }
  }, [updateState]);

  // Memoized filtering logic
  const filteredPujas = useMemo(() => {
    const { active, lifeEventList, allPujas, occEventList, filter } = state;
  
    let baseList;
    if (active === "Life Event Based") {
      baseList = lifeEventList;
    } else if (active === "All Pujas") {
      baseList = allPujas;
    } else {
      baseList = occEventList;
    }
  
    const mappedPujas = mapPujasToDisplayFormat(baseList, active);
    return mappedPujas.filter(puja =>
      puja.name.toLowerCase().includes(filter.toLowerCase())
    );
  }, [state.active, state.filter, state.lifeEventList, state.allPujas, state.occEventList]);

  // Fetch data on active tab change
  useEffect(() => {
    fetchPujasData();
    fetchAllPujasList();
  }, [state.active]);

  // Category Tab Component (extracted to reduce complexity)
  const CategoryTab = React.memo(({
    icon,
    title,
    iconAlt,
    isMainCategory,
    className,
    tabName,
  }) => (
    <Tab
      className={`tw-flex tw-flex-col tw-items-center tw-gap-1 sm:tw-px-5 tw-h-[100px] ${
        className || ""
      }`}
      onClick={() => changeTab(tabName)}
      style={{ borderBottom: state.active === tabName ? "4px solid #5a1740" : "" }}
    >
      <Img src={icon} alt={iconAlt} className="tw-h-[42px] tw-w-[42px]" loading="lazy"/>
      <Title
        size={isMainCategory ? "headingxs" : "texts"}
        as={isMainCategory ? "h2" : "h3"}
        className={`
          tw-font-quicksand 
          tw-text-[14px] 
          tw-w-[70px]
          tw-text-center
          ${
            isMainCategory
              ? "tw-font-semibold tw-text-primary_maroon"
              : "tw-font-medium tw-text-gray-900"
          }
        `}
      >
        {title}
      </Title>
    </Tab>
  ));

  // Category Dropdown Component (simplified)
  const CategoryDropdown = React.memo(({ categories }) => {
    const toggleDropdown = () => 
      updateState({ isDropdownOpen: !state.isDropdownOpen });

    return (
      <div className="container tw-hidden md:tw-block tw-relative tw-w-full tw-bg-white tw-p-0">
        <button
          onClick={toggleDropdown}
          className="tw-w-full tw-bg-[white] tw-flex tw-items-center tw-justify-between tw-px-4 tw-py-2 tw-bg-white tw-border-[#000] tw-border-[1px] tw-text-[black]"
        >
          <span className="tw-font-medium">{state.active}</span>
          <svg
            className={`tw-w-5 tw-h-5 tw-transition-transform ${
              state.isDropdownOpen ? "tw-rotate-180" : ""
            }`}
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path d="M19 9l-7 7-7-7" strokeWidth="2" strokeLinecap="round" />
          </svg>
        </button>

        {state.isDropdownOpen && (
          <div className="tw-absolute tw-z-10 tw-mt-1 tw-border">
            {categories.map((category) => (
              <button
                key={category.id}
                onClick={() => {
                  changeTab(category.title);
                  updateState({ isDropdownOpen: false });
                }}
                className={`
                tw-w-full tw-bg-[white] tw-px-4 tw-py-2 tw-text-left hover:tw-bg-gray-50 tw-border-[#000] tw-border-[1px]
                ${state.active === category.title ? "tw-bg-gray-100" : ""}
              `}
              >
                <div className="tw-flex tw-items-center tw-gap-2">
                  <span className="tw-font-medium">{category.title}</span>
                </div>
              </button>
            ))}
          </div>
        )}
      </div>
    );
  });

  return (
    <>
      <div className="tw-mb-[11px] tw-flex tw-flex-col tw-items-center">
        <div className="tw-hidden tw-w-full tw-items-center sm:tw-block sm:tw-w-[95%]">{/* adjusted the text size */}
          <BannerSection />
        </div>
        <div className="container tw-flex tw-flex-col tw-gap-5 sm:tw-gap-[31px]">
          <div className="tw-flex tw-flex-col tw-items-start tw-gap-10 sm:tw-gap-[0]">
            <Text
              as="p"
              className="tw-text-[70px] tw-font-normal tw-text-primary_maroon md:tw-text-[48px] sm:tw-text-[30px] sm:tw-w-[90%]"
            >
              Rituals brings goodness
            </Text>

            <SearchBar 
              filter={state.filter} 
              setFilter={(value) => updateState({ filter: value })} 
              searchList={filteredPujas} 
              redirection={redirection}
            />
          </div>

          <div className="tw-flex tw-flex-col tw-items-start tw-gap-5 sm:tw-gap-0">
            <Title
              size="textlg"
              as="h1"
              className="tw-text-[24px] tw-font-medium tw-text-primary_maroon md:tw-text-[22px] sm:tw-text-[14px] sm:tw-text-[black]"
            >
              Choose by Categories
            </Title>

            <TabList className="container tw-flex tw-items-center tw-justify-between tw-self-stretch tw-border-gray-900 md:tw-hidden">{/*adjusted the alignment */}
              {PUJA_CATEGORIES.map((category) => (
                <CategoryTab key={category.id} {...category}/>//adjusted the alignment
              ))}
            </TabList>

            <CategoryDropdown categories={PUJA_CATEGORIES} />
          </div>

          {/* Contact Us section */}
          <div
            className="not-found-container"
            style={{ display: state.showContactUs ? "flex" : "none" }}
          >
            <div className="not-found-text">
              <p>Looking for something else?</p>
              <p>Please reach out to us.</p>
            </div>
            <div className="not-found-contact">
              <button className="contact-us-button contact-btn-offering">
                <a href="/contact">Contact Us</a>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="tw-w-full tw-items-center tw-mt-[50px] sm:tw-hidden">
        <BannerSection/>
      </div>
      <CardsSection pujas={filteredPujas} />
    </>
  );
}