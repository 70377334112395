import axiosInstance from "./AxiosInstance";
import {debounce} from "../Utils/Debounce";

export const UserService = {
  getUserDetails: debounce (async() => {
    const response = await axiosInstance.get("/users");
    return response.data.data;
  },300),
  registerUser: debounce (async(data) => {     //function to register user
    return axiosInstance.post("/users/register-user", data);
  },300),
  updateUser: debounce (async(payload) => {
    return axiosInstance.post("/users/create-new", payload); // removed the await
  },300),
};
