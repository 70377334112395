/* eslint-disable no-unused-vars */
import React,{useEffect} from "react";
import "./App.css";
import { initGA, logPageView } from './Utils/Analytics';
import { Route, Routes,useLocation} from "react-router-dom";


import About from "./Page/AboutUs/AboutUs";
import Pooja from "./Page/PujaDetailPage/PujaDetailPage.jsx";
import WhoIsPooja from "./Page/WhoIsPooja/WhoIsPooja";
import Contact from "./Page/ContactUs/Contact";
import Refund from "./Page/Refund/Refund";
import Privacy from "./Page/PrivacyPolicy/PrivacyPolicy";
import TermsAndContions from "./Page/TermsAndConditions/TermsAndConditions";
import Menu from "./Component/Menu/Menu";
import Profile from "./Page/MyProfile/MyProfile";
import MyBooking from "./Page/MyBooking/MyBooking";
import Thankyou from "./Page/Thankyou/Thankyou";
import DharmaPage from "./Page/Dharma/index.jsx";
import BookingDetail from "./Page/BookingDetailPage/BookingDetail";
import { Bookings } from "./Page/BookingPage/Booking";
import HomePage from "./Page/HomePage/index.jsx";
import OfferingsPage from "./Page/OfferingsPage/index.jsx";
function App() {

  const location = useLocation();

  useEffect(() => {
    initGA();
    logPageView();
    window.addEventListener('popstate', logPageView);
  }, []);
  
  useEffect(() => {      //to scroll to top on every route change
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant' // This prevents the smooth scrolling animation
    });
  }, [location.pathname]);


  return (
    <div> 
      <Routes>
          {/* <Route exact path="/library" element={ <Libary />} /> */}
          <Route exact path="/offerings" element={ <OfferingsPage />} />
          <Route exact path="/refund-cancellation-policy" element={ <Refund />} />
          <Route exact path="/privacy-policy" element={ <Privacy />} />
          <Route exact path="/terms-conditions" element={ <TermsAndContions />} />
          <Route exact path="/contact" element={ <Contact />} />
          <Route exact path="/" element={<HomePage />} />
          <Route exact path="/poojas/telugu/:pujaName" element={ <Pooja />} />
          <Route exact path="/pujaris" element={ <WhoIsPooja />} />
          <Route exact path="/about" element={ <About />} />
          <Route exact path="/menu" element={ <Menu />} />
          <Route exact path="/profile" element={ <Profile />} />
          <Route exact path="/mybookings" element={ <MyBooking />} />
          <Route exact path="/booking-success" element={ <Thankyou />} />
          <Route exact path="/mydharma"  element={<DharmaPage />} />
          <Route exact path="/my-bookings/:bookingNumber" element={ <BookingDetail />} />   {/* changed the route to accpet the booking id */}
        </Routes>
    </div>
  );
}

export default App;
