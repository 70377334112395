/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from "react";
import "./PujaDetailPage.scss";
import "../LandingPage.scss";
import Footer from "../../Component/Footer/Footer";
import Whatsapp from "../../Component/Whatsapp/Whatsapp";
import seoData from "../../Seo";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Modal from "../../Component/Modal/Modal";
import { useNavigate } from "react-router-dom";
import LoginModal from "../../Component/Login/Login";
import { Helmet } from "react-helmet";
import Header from "../../Component/Header";
import { PujaService, UserService, BookingService } from "../../Services/index";
import Loader from "../../Component/Loader/Loader";
import {logEvent,logPageView} from "../../Utils/Analytics";
import { EVENT_CATEGORIES, EVENT_ACTIONS, EVENT_LABELS } from "../../Constants/myDharmaContent";
export default function PujaDetailPage() {
  const [pujaDetails, setPujaDetails] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [notes, setNotes] = useState("");
  const [samagriList, setSamagriList] = useState([]);
  const [loginModal, setLoginModal] = useState(false);
  const [showSamagri, setShowSamagri] = useState(false);
  const [activeFaq, setActiveFaq] = useState(null);
  const [pujaList, setPujaList] = useState([]);
  const [activeDiya, setActiveDiya] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [invoiceDetails, setInvoiceDetails] = useState(false);
  const [isPujariAdded, setIsPujariAdded] = useState(true);
  const [isSamagriAdded, setIsSamagriAdded] = useState(true);
  const [pujariCost, setPujariCost] = useState(0);
  const [samagriCost, setSamagriCost] = useState(0);
  const [addonSamagriCost, setAddonSamagriCost] = useState(0);
  const [defaultSamagri, setDefaultSamagri] = useState([]);
  const [addonSamagri, setAddonSamagri] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [history, setHistory] = useState([]);
  const [totalSamagri, setTotalSamagri] = useState([]);
  const [historyData, setHistoryData] = useState([]);
  const [videos, setVideos] = useState([]);
  const [userSamagri, setUserSamagri] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [toggleSamagri, setToggleSamagri] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [serviceFee, setServiceFee] = useState(0);
  const [gst, setGst] = useState(0);
  const [samagri, setSamagri] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [signupFormData, setSignupFormData] = useState({
    name: "",
    surname: "",
    phoneNumber: "",
    gender: "",
    country: "",
    state: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    pincode: "",
  });
  const [formData, setFormData] = useState({
    name: "",
    surname: "",
    gender: "",
    country: "INDIA",
    addressLine1: "",
    addressLine2: "",
    city: "Hyderabad",
    pincode: "",
    states: "Telangana",
    muhurtam: "",
    muhurtam_fixed: "no",
  });
  const pathname = window.location.pathname;
  const urlName = pathname.split("/").pop(); // Extracting the url_name from the URL
  const modalRef = useRef();
  const navigate = useNavigate();

  //to make the carousel videos responsive set the breakpoints
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    // variableWidth: false,
    // slidesToShow:3,
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1346,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 465,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    if(pujaDetails?.url_name){
    document.title = `${pujaDetails?.name}-Karishye`;
      logPageView(pujaDetails?.url_name);
    }
  }, [pujaDetails]);
  const toggleFaq = (index) => {
    setActiveFaq(activeFaq === index ? null : index);
  };

  const handleInterestedClick = () => {
    logEvent(EVENT_CATEGORIES.PUJA_BOOKING(pujaDetails?.url_name),EVENT_ACTIONS.INTERESTED_BUTTON(pujaDetails?.url_name),EVENT_LABELS.CLICK); 
  };

  const showDiya = (index) => {
    setActiveDiya(index);
  };

  const hideSamagri = () => {
    setShowSamagri(!showSamagri);
  };

  const handleImageError = (e) => {
    e.target.onerror = null;
    e.target.src = `${process.env.REACT_APP_IMAGE_URL_1236_500}/default-no-puja-image.webp`
  };

  const calculateServiceFeeWithGST = (fee) => {
    //function to calculate gst on service charges
    return Math.round(fee * 1.18);
  };

  const togglePujariCost = () => {
    const pujariCostWithoutGST = pujaDetails?.pujamodels[0].pujariCost || 0;
    if (isPujariAdded) {
      setTotalCost(Math.round(totalCost - pujariCostWithoutGST));
    } else {
      setTotalCost(Math.round(totalCost + pujariCostWithoutGST));
    }
    setIsPujariAdded(!isPujariAdded);
  };

  const toggleSamagriCost = () => {
    const samagriCostWithoutGST = samagriCost; // Assuming this is already without GST
    if (isSamagriAdded) {
      setSamagri([]);
      setTotalCost(Math.round(totalCost - samagriCostWithoutGST));
    } else {
      setSamagri(totalSamagri);
      setTotalCost(Math.round(totalCost + samagriCostWithoutGST));
    }
    setIsSamagriAdded(!isSamagriAdded);
  };

  const addSamagri = (puja) => {
    const updatedSamagri = [...totalSamagri, puja];
    setTotalSamagri(updatedSamagri); // Updated the totalSamagri state
    setSamagri(updatedSamagri);
    setAddonSamagriCost(addonSamagriCost + puja.customerMrp * puja?.qty);
    setSamagriCost(samagriCost + puja.customerMrp * puja?.qty);
    setToggleSamagri({ ...toggleSamagri, [puja.id]: true });
  };

  const removeSamagri = (puja) => {
    const updatedSamagri = totalSamagri.filter((item) => item.id !== puja.id);
    setTotalSamagri(updatedSamagri); // Updated the totalSamagri state
    setSamagri(updatedSamagri); // Update the samagri state
    setSamagriCost(samagriCost - puja.customerMrp * puja?.qty);
    setAddonSamagriCost(addonSamagriCost - puja.customerMrp * puja?.qty);
    setToggleSamagri({ ...toggleSamagri, [puja.id]: false });
  };

  const fetchAllPujas = async () => {
    setIsLoading(true);
    try {
      const response = await PujaService.getPujaList();
      if (!response) {
        throw new Error("Failed to fetch pujas");
      }
      return response;
    } catch (error) {
      console.error("Error fetching pujas:", error);
      return null;
    }finally {
      setIsLoading(false);
    }
  }; //fetching all pujas from API call

  const fetchPujaDetails = async (pujaId) => {
    try {
      const response = await PujaService.getPujaDetails(pujaId);
      if (!response) {
        throw new Error("Failed to fetch puja details");
      }
      return response;
    } catch (error) {
      console.error("Error fetching puja details:", error);
      return null;
    }
  }; //fetching puja details from api call

  const fetchSamagriList = async (pujaId) => {
    try {
      const response = await PujaService.getSamagriList(pujaId);
      if (!response) {
        throw new Error("Failed to fetch samagri list");
      }
      return response;
    } catch (error) {
      console.error("Error fetching samagri list:", error);
      return null;
    }
  }; //fetching samagri list from api call

  // Combined fetch function
  const fetchPujaData = async () => {
    try {
      const allPujas = await fetchAllPujas();
      if (!allPujas) return;

      const matchedPuja = allPujas.find((puja) => puja?.url_name === urlName);
      if (!matchedPuja) {
        throw new Error(`No puja found with url: ${urlName}`);
      }

      const details = await fetchPujaDetails(matchedPuja.id);
      if (details) {
        setPujaDetails(details);
      }

      const fetchedSamagri = await fetchSamagriList(matchedPuja.id);
      if (fetchedSamagri) {
        setSamagriList(fetchedSamagri);
      }
    } catch (error) {
      console.error("Error in fetchPujaData:", error);
    }
  }; //fetching all puja data from API call

  // Handle different YouTube URL formats
  const getVideoId = (url) => {
    try {
      if (url.includes("youtube.com/embed/")) {
        return url.split("embed/")[1].split("?")[0];
      }
      if (url.includes("youtu.be/")) {
        return url.split("youtu.be/")[1].split("?")[0];
      }
      if (url.includes("youtube.com/watch?v=")) {
        return url.split("v=")[1].split("&")[0];
      }
      return url.split("/").pop().split("?")[0];
    } catch (error) {
      console.error("Error extracting video ID:", error);
      return null;
    }
  };

  useEffect(() => {
    // Validate: letters with hyphens, no numbers-only, no special chars
    if (!urlName || !/^(?!^\d+$)[a-zA-Z]+(?:-[a-zA-Z]+)*$/.test(urlName)) {
      console.error("Invalid puja URL format");
      return;
    }
    fetchPujaData();
  }, [urlName]);

  const handleOpenModal = (videoId) => {
    setSelectedVideo(videoId);
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    setSelectedVideo(null);
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      handleCloseModal();
    }
  };

  useEffect(() => {
    if (modalVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalVisible]);

  useEffect(() => {
    if (pujaDetails?.pujamodels?.[0]) {
      const serviceFee = pujaDetails.pujamodels[0].serviceFee || 0;
      setServiceFee(serviceFee);
      let initialTotalCost = calculateServiceFeeWithGST(serviceFee);
      setGst(initialTotalCost - serviceFee); //calculating gst on servie fee
      if (isPujariAdded) {
        initialTotalCost += pujariCost;
      }

      if (isSamagriAdded) {
        initialTotalCost += samagriCost;
      }

      setTotalCost(Math.round(initialTotalCost));
    }
  }, [isPujariAdded, isSamagriAdded, pujaDetails, samagriCost, pujariCost]);

  useEffect(() => {
    const fetchPujas = async () => {
      try {
        const threeCards = await PujaService.getRelatedPujas(1);
        setPujaList(threeCards);
        return threeCards;
      } catch (err) {
        console.log("err", err);
      }
    };

    fetchPujas();
    const handleScroll = () => {
      const sections = ["puja-procedure", "faq", "history", "how-it-works"];

      sections.forEach((sectionId) => {
        const section = document.getElementById(sectionId);
        if (!section) return;
        const rect = section.getBoundingClientRect();
        const viewportHeight = document.documentElement.clientHeight;
        if (
          (rect.top >= 0 && rect.top < viewportHeight) ||
          (rect.bottom > 0 && rect.bottom <= viewportHeight)
        ) {
          switch (sectionId) {
            case "puja-procedure":
              showDiya(0);
              break;
            case "faq":
              showDiya(3);
              break;
            case "history":
              showDiya(1);
              break;
            case "how-it-works":
              showDiya(2);
              break;
            default:
              break;
          }
        }
      });
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const fetchDataAndVideos = async () => {
      try {
        const fetchedHistory = await PujaService.getPujaHistory();
        setHistory(fetchedHistory);

        if (pujaDetails) {
          const historyDat =
            fetchedHistory.find((puja) => puja?.name === pujaDetails?.name) ||
            [];

          if (historyDat) {
            setHistoryData(historyDat?.pujaHistory);

            try {
              const videos = JSON.parse(historyDat?.videos || "[]");
              setVideos(videos);
            } catch (error) {
              console.error("Error parsing videos:", error);
              setVideos([]);
            }
          }
        }
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    };

    fetchDataAndVideos();
  }, [pujaDetails]);
  useEffect(() => {
    if (isPujariAdded) {
      setPujariCost(pujaDetails?.pujamodels?.[0]?.pujariCost || 0);
    } else {
      setPujariCost(0);
    }
  }, [isPujariAdded, pujaDetails]);

  useEffect(() => {
    if (isSamagriAdded) {
      const calculatedCost = defaultSamagri.reduce((total, item) => {
        return total + item.customerMrp * item.qty;
      }, 0);
      setSamagriCost(calculatedCost+addonSamagriCost);
    } else {
      setSamagriCost(0+addonSamagriCost);
    }
  }, [isSamagriAdded, pujaDetails, defaultSamagri]);
  const user = localStorage.getItem("userId"); //creating booking data for puja booking with all required fields
  const createBookingData = () => {
    const user = localStorage.getItem("userId");
    return {
      data: {
        userId: user,
        eventType: "at_my_home",
        whomPuja: "self",
        state: "active",
        samagriStatus: "samagri_under_review",
        serviceFee: pujaDetails?.pujamodels?.[0]?.serviceFee,
        bookingSellingPrice: totalCost,
        gst: gst,
        pujaId: pujaDetails?.id,
        bookingStatus: "submitted",
        model: pujaDetails?.pujamodels?.[0]?.id,
        noOfPujaris: pujaDetails?.pujamodels?.[0]?.noOfPujaris,
        pujariCost: pujariCost,
        address: formData.addressLine1 + formData.addressLine2,
        customerNotes: notes,
      },
      samagri: samagri,
      procedures: pujaDetails?.pujamodels?.[0]?.procedures.map(
        (puja) => puja.procedureId
      ),
    };
  };
  const handleSubmit = async () => {
    const user = localStorage.getItem("userId");
    if (user) {
      const data = createBookingData();
      try {
        const result = await BookingService.createBooking(user, data);
        console.log("Success:", result);
        localStorage.setItem(
          "BookingId",
          result?.data?.data?.generatedMaps[0]?.id
        ); //storing booking id in local storage
      } catch (error) {
        console.error("Error:", error);
      }
      navigate("/booking-success");
    } else {
      hideLogin();
    }
  };
  const data = createBookingData();
  useEffect(() => {
    const { defaultItems, addonItems, userItems } = samagriList.reduce(
      (acc, item) => {
        if (item?.isProvidedBy === "user" && item?.samagriType === "default") {
          acc.userItems.push(item);
        }
        if (
          item?.isProvidedBy === "karishye" &&
          item?.samagriType === "default"
        ) {
          acc.defaultItems.push(item);
        } else if (item?.samagriType === "addon") {
          acc.addonItems.push(item);
        }
        return acc;
      },
      { defaultItems: [], addonItems: [], userItems: [] }
    );

    setDefaultSamagri(defaultItems);
    setTotalSamagri(defaultItems);
    setSamagri(defaultItems);
    setAddonSamagri(addonItems);
    setUserSamagri(userItems);
  }, [samagriList]);

  const hideLogin = () => {
    setLoginModal(!loginModal);
    setPhoneNumber("+91");
  };

  useEffect(() => {
    const fetchUser = async () => {
      try {
        //fetching user data from the backend
        const users = UserService.getUserDetails();
        const userdetails = Object.values(users).find((u) => u.name == user);
        if (userdetails) {
          setFormData({
            name: userdetails.name || "",
            surname: userdetails.surname || "",
            gender: userdetails.gender || "",
            country: userdetails.country || "INDIA",
            states: userdetails.state || "Telangana",
            addressLine1: userdetails.addressLine1 || "",
            addressLine2: userdetails.addressLine2 || "",
            city: userdetails.city || "Hyderabad",
            pincode: userdetails.pincode || "",
          });
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    if (user && pujaDetails?.id) {
      fetchUser();
    }
  }, [user, pujaDetails?.id]);

  useEffect(() => {
    setSignupFormData({
      name: formData.name,
      surname: formData.surname,
      gender: formData.gender,
      country: formData.country,
      state: formData.states,
      addressLine1: formData.addressLine1,
      addressLine2: formData.addressLine2,
      city: formData.city,
      pincode: formData.pincode,
      phoneNumber: "",
    });
  }, [formData]);

  const handleNotesChange = (event) => {
    //storing notes in local storage
    const value = event.target.value;
    setNotes(value);
  };
   //function to update the total cost of the puja
  const updatedTotalCost=()=>{
    setTotalCost(samagriCost + pujariCost + pujaDetails?.pujamodels?.[0]?.serviceFee+gst);
  }

  useEffect(() => {
    updatedTotalCost();
  }, [samagriCost,pujariCost,pujaDetails?.pujamodels?.[0]?.serviceFee,gst]);
  const calculateSamagriQuantity = (standardQty, qty, units) => {
    try {
      const standardQuantity = Number(standardQty) || 0;
      const quantity = Number(qty) || 0;
      const totalQuantity = standardQuantity * quantity;
      return `${totalQuantity} ${units || ""}`.trim();
    } catch (error) {
      console.error("Error calculating samagri quantity:", error);
      return "0";
    }
  };

  const pageUrl = seoData[pujaDetails?.url_name]?.PageURL;
  const metaTitle = seoData[pujaDetails?.url_name]?.MetaTitle;
  const metaDescription = seoData[pujaDetails?.url_name]?.MetaDescription;
  const metaKeywords = seoData[pujaDetails?.url_name]?.Keywords;
  const h1 = seoData[pujaDetails?.url_name]?.H1Tag;
  const ToBooking = false;
  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta name="keywords" content={metaKeywords} />
        <link rel="canonical" href={pageUrl} />
      </Helmet>
      <h1 className="hiddenTag">{h1}</h1>
      <Header />
      {isLoading && <Loader fullScreen />}
      <div className="PoojaDeatailpage">
        <div className="container" id="Pooja-detail">
          <div className="details-top-img">
            <div className="puja-container">
              <div className="image-slideshow-container">
                <div className="img-slide" style={{ display: " block" }}>
                  <img
                    src={
                      `${process.env.REACT_APP_IMAGE_URL_1236_500}/${pujaDetails?.url_name?.trim()}.webp`}
                    alt={`${pujaDetails.name}-image`}
                    className="Puja-Image-Full"
                    loading="lazy"
                    onError={handleImageError}
                  />
                </div>
              </div>
              <br />
              <div
                className="img-dot-container"
                style={{ textAlign: "center" }}
              >
                <span className="change-img-dot active"></span>
              </div>
            </div>
          </div>

          <div className="details-head-sec">
            <div className="details-head-lft">
              <div className="details-heading align-items-center">
                <div className="heading-wrapper">
                  <h2 className="puja-name-heading">{pujaDetails?.name}</h2>
                </div>
                <div className="available-modes align-items-center pb-lg-4 pb-md-3">
                  <div className="available-box d-flex align-items-center">
                    <img src="/assets/images/mode1.png" alt="Mode1-Icon" loading="lazy"/>
                    <p>Online</p>
                  </div>
                  <div className="available-box d-flex align-items-center">
                    <img src="/assets/images/mode2.png" alt="Mode2-Icon" loading="lazy"/>
                    <p>In Person</p>
                  </div>
                  <div className="available-box d-flex align-items-center">
                    <img src="/assets/images/mode3.png" alt="Mode3-Icon" loading="lazy"/>
                    <p>Assisted</p>
                  </div>
                </div>
                <div className="border-line">
                  <p></p>
                </div>
                <div className="puja-features">
                  <div>
                    <img
                      src="/assets/images/verified-pujaris.png"
                      alt="Pujaris-Image"
                      loading="lazy"
                    />
                    <p className="text-breakdown">Verified Pujaris</p>
                  </div>
                  <div>
                    <img
                      src="/assets/images/pure-organic-samagri.png"
                      alt="Samagri-Icon"
                      loading="lazy"
                    />
                    <p className="text-breakdown">Pure Organic Samagri</p>
                  </div>
                  <div>
                    <img
                      src="/assets/images/free-rescheduling.png"
                      alt="Rescheduling-Icon"
                      loading="lazy"
                    />
                    <p className="text-breakdown">Free rescheduling</p>
                  </div>
                  <div>
                    <img src="/assets/images/chat-support.png" alt="Chat-Icon" loading="lazy"/>
                    <p className="text-breakdown">Chat support</p>
                  </div>
                  <div>
                    <img
                      src="/assets/images/no-cancellation-charges.png"
                      alt="Cancellation-Icon"
                      loading="lazy"
                    />
                    <p className="text-breakdown">
                      No cancellation charges (until last 48hrs)
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="details-head-rht">
              <div className="price-box">
                <div>
                  <p className="price-header">Typical Charges</p>
                </div>
              </div>
              <div className="price-box">
                <div>
                  <div>
                    <p>Pujari</p>
                    {isPujariAdded ? (
                      <>
                        <p className="numberOfPujaries">
                          {" "}
                          ( {pujaDetails?.pujamodels?.[0]?.noOfPujaris} )
                        </p>
                        <div
                          className="samagri-additional-btn pujari-cost-remove-btn margin-button"
                          onClick={() => togglePujariCost()}
                        >
                          <img
                            src="/assets/images/delete-icon.svg"
                            alt="Delete-Icon"
                            loading="lazy"
                          />
                          Remove
                        </div>
                      </>
                    ) : (
                      <>
                        <p className="numberOfPujaries"> ( {0} )</p>
                        <button
                          className="samagri-additional-btn samagri-cost-add-btn margin-button"
                          onClick={() => togglePujariCost()}
                        >
                          Add
                        </button>
                      </>
                    )}
                  </div>
                  <p className="pujari-cost">₹ {pujariCost}</p>
                </div>
              </div>
              <div className="price-box">
                <div>
                  <div className="price-box-samagri">
                    <p className="samagri-text">
                      Puja Samagri Set
                      {isSamagriAdded ? (
                        <div
                          className="samagri-additional-btn pujari-cost-remove-btn"
                          onClick={() => toggleSamagriCost()}
                        >
                          <img
                            src="/assets/images/delete-icon.svg"
                            alt="Delete-Icon"
                            loading="lazy"
                          />
                          Remove
                        </div>
                      ) : (
                        <button
                          className="samagri-additional-btn samagri-cost-add-btn"
                          onClick={() => toggleSamagriCost()}
                        >
                          Add
                        </button>
                      )}
                    </p>
                  </div>
                  <p className="samagri-cost">₹ {samagriCost}</p>
                </div>
                <a
                  className="view-btn"
                  href="#samagri-header"
                  onClick={() => hideSamagri()}
                >
                  View
                </a>
              </div>
              <div className="price-box service-charges">
                <div>
                  <div>
                    <p>Karishye charges</p>
                  </div>
                  <p className="service-charge-price">
                    ₹ {pujaDetails?.pujamodels?.[0]?.serviceFee ?? 0}
                  </p>
                </div>
              </div>
              <div className="price-box service-charges">
                <div>
                  <div>
                    <p>GST</p>
                    <div className="total-contribute">
                      <img
                        className="info-icon"
                        src="/assets/images/info-outline.png"
                        alt="Info-Icon"
                        loading="lazy"
                      />
                      <div className="show-total-tooltip tooltip-right">
                        <span>
                          Input tax credit charged by the pujari (if any) will
                          be available with them as the service provider.
                          Karishye acts as a facilitator for these services
                        </span>
                      </div>
                    </div>
                  </div>
                  <p className="service-charge-price">₹ {gst}</p>
                </div>
              </div>{" "}
              <div className="edit-service-charges">
                <input className="service-charge-input" type="number" />
                <button className="apply-charge-btn">Apply</button>
              </div>
              <div className="price-box tw-border-b-0">
                <div>
                  <div>
                    <div className="total-amount">
                      <div className="total-amount-container">
                        <p className="total">Total payable</p>
                        <div className="total-contribute">
                          <img
                            className="info-icon"
                            src="/assets/images/info-outline.png"
                            alt="Info-Icon"
                            loading="lazy"
                          />
                          <div className="show-total-tooltip tooltip-right">
                            <span>
                              By paying a little bit of taxes on your puja, you
                              are indirectly doing a great service to the
                              country and our pujari family. We thank you 🙏
                            </span>
                          </div>
                        </div>
                      </div>
                      <p className="totalSubDetail">(Incl. of all Taxes) </p>
                    </div>
                  </div>
                  <p className="price">₹ {totalCost}</p>
                </div>

                <div className="details-book-now-mobile">
                  <a
                    onClick={() => {
                      handleSubmit();
                      handleInterestedClick();
                    }}
                    className="bookbut"
                  >
                    I&#39;m Interested
                  </a>
                </div>
              </div>
              <p className="tw-text-[11px] tw-font-quicksand">* This is indicative price only. Final rates might vary based on shaktha, sampradayam etc.</p>
              <div className="details-book-now">
                <a
                  onClick={() => {
                    handleSubmit();
                    handleInterestedClick();
                  }}
                  className="bookbut"
                >
                  I&#39;m Interested
                </a>
              </div>
            </div>
          </div>

          <div className="details-bottom-sec">
            <div className="right-side-floater">
              <div
                className="price-book-floater"
                style={{ display: invoiceDetails ? "flex" : "none" }}
              >
                <div className="hide-total-amount">
                  <div className="total-amount">
                    <div className="total-amount-container">
                      <p className="total">Total payable</p>
                      <div className="total-contribute">
                        <img
                          className="info-icon"
                          src="/assets/images/info-outline.png"
                          alt="Info-Icon"
                          loading="lazy"
                        />
                        <div className="show-total-tooltip tooltip-right">
                          <span>
                            By paying a little bit of taxes on your puja, you
                            are indirectly doing a great service to the country
                            and our pujari family. We thank you 🙏
                          </span>
                        </div>
                      </div>
                    </div>
                    <p className="totalSubDetail"> (Incl. of all Taxes) </p>
                  </div>
                  <p className="floater-price">₹{totalCost}</p>
                </div>
                <p className="tw-text-[11px] tw-font-quicksand">* This is indicative price only. Final rates might vary based on shaktha, sampradayam etc.</p>
                <div className="details-book-now" style={{ width: "100%" }}>
                  <a
                    onClick={() => {
                      handleSubmit();
                      handleInterestedClick();
                    }}
                    className="bookbut"
                  >
                    I&#39;m Interested
                  </a>
                </div>
              </div>

              <div
                style={{ display: invoiceDetails ? "flex" : "none" }}
                className="details-show-display"
                onClick={() => setInvoiceDetails(!invoiceDetails)}
              >
                <p className="show-details">Show details</p>
              </div>

              <div
                className="invoice-and-samagri"
                style={{ display: invoiceDetails ? "none" : "flex" }}
              >
                <div className="price-box">
                  <div>
                    <p className="price-header">Typical Charges</p>
                  </div>
                </div>
                <div className="price-box">
                  <div>
                    <div className="pujari-button">
                      <p>Pujari</p>
                      {isPujariAdded ? (
                        <>
                          <p className="numberOfPujaries">
                            ( {pujaDetails?.pujamodels?.[0]?.noOfPujaris} )
                          </p>
                          <div
                            className="samagri-additional-btn pujari-cost-remove-btn"
                            onClick={() => {
                              togglePujariCost();
                            }}
                          >
                            <img
                              src="/assets/images/delete-icon.svg"
                              alt="Delete-Icon"
                              loading="lazy"
                            />
                            Remove
                          </div>
                        </>
                      ) : (
                        <>
                          <p className="numberOfPujaries">( {0} )</p>
                          <button
                            className="samagri-additional-btn samagri-cost-add-btn"
                            onClick={() => {
                              togglePujariCost();
                            }}
                          >
                            Add
                          </button>
                        </>
                      )}
                    </div>
                    <p className="pujari-cost">₹ {pujariCost}</p>
                  </div>
                </div>
                <div className="price-box">
                  <div className="samagri-invoice-col">
                    <div className="price-box-samagri">
                      <div>
                        <p className="samagri-text">Puja Samagri Set</p>
                      </div>
                      {isSamagriAdded ? (
                        <div
                          className="samagri-additional-btn pujari-cost-remove-btn"
                          onClick={() => toggleSamagriCost()}
                        >
                          <img
                            src="/assets/images/delete-icon.svg"
                            alt="Delete-Icon"
                            loading="lazy"
                          />
                          Remove
                        </div>
                      ) : (
                        <button
                          className="samagri-additional-btn samagri-cost-add-btn"
                          onClick={() => toggleSamagriCost()}
                        >
                          Add
                        </button>
                      )}
                      <a
                        className="view-samagri-btn"
                        href="#samagri-header"
                        onClick={() => hideSamagri()}
                      >
                        View
                      </a>
                    </div>
                    <p className="samagri-cost">₹ {samagriCost}</p>
                  </div>
                  <a
                    className="view-samagri-button"
                    href="#samagri-header"
                    onClick={() => hideSamagri()}
                  >
                    View
                  </a>
                </div>
                <div className="price-box service-charges-floater">
                  <div>
                    <div>
                      <p>Karishye charges</p>
                    </div>
                    <p className="service-charge-price-floater">
                      ₹ {pujaDetails?.pujamodels?.[0]?.serviceFee ?? 0}
                    </p>
                  </div>
                </div>
                <div className="price-box">
                  <div>
                    <div className="total-amount">
                      <div className="total-amount-container">
                        <p className="service-charge-price-floater">GST</p>
                        <div className="total-contribute">
                          <img
                            className="info-icon"
                            src="/assets/images/info-outline.png"
                            alt="Info-Icon"
                            loading="lazy"
                          />
                          <div className="show-total-tooltip tooltip-right">
                            <span>
                              Input tax credit charged by the pujari (if any)
                              will be available with them as the service
                              provider. Karishye acts as a facilitator for these
                              services
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p className="service-charge-price-floater">₹ {gst}</p>
                  </div>
                </div>
                <div className="edit-service-charges-floater">
                  <input
                    className="service-charge-input-floater"
                    type="number"
                    defaultValue={totalCost}
                  />
                  <button className="apply-charge-btn">Apply</button>
                </div>
                <div className="price-box">
                  <div>
                    <div className="total-amount">
                      <div className="total-amount-container">
                        <p className="total">Total payable</p>
                        <div className="total-contribute">
                          <img
                            className="info-icon"
                            src="/assets/images/info-outline.png"
                            alt="Info-Image"
                            loading="lazy"
                          />
                          <div className="show-total-tooltip tooltip-right">
                            <span>
                              By paying a little bit of taxes on your puja, you
                              are indirectly doing a great service to the
                              country and our pujari family. We thank you 🙏
                            </span>
                          </div>
                        </div>
                      </div>
                      <p className="totalSubDetail">(Incl. of all Taxes) </p>
                    </div>
                    <p className="floater-detail-price">₹ {totalCost}</p>
                  </div>
                  <p className="tw-text-[11px]">* This is indicative price only. Final rates might vary based on shaktha, sampradayam etc.</p>
                  <div className="details-book-now-mobile">
                    <a
                      onClick={() => {
                        handleSubmit();
                        handleInterestedClick();
                      }}
                      className="bookbut"
                    >
                      I&#39;m Interested
                    </a>
                  </div>
                </div>
                <div className="details-book-now" style={{ width: "100%" }}>
                  <a
                    onClick={() => {
                      handleSubmit();
                      handleInterestedClick();
                    }}
                    className="bookbut"
                  >
                    I&#39;m Interested
                  </a>
                </div>
              </div>

              {!invoiceDetails && (
                <div className="details-hide-display">
                  <p
                    className="hide-details"
                    onClick={() => setInvoiceDetails(!invoiceDetails)}
                  >
                    Hide details
                  </p>
                </div>
              )}
              <div className="link-container">
                <div className="link-container-links">
                  <div>
                    <a href="#puja-summary" onClick={() => showDiya(0)}>
                      Puja Procedure
                    </a>
                    <img
                      className={`diya-nav-img ${
                        activeDiya === 0 ? "diya-active" : ""
                      }`}
                      src="/assets/images/diya-navigator.png"
                      alt="Diya-Image"
                      loading="lazy"
                    />
                  </div>
                  <div>
                    <a href="#history" onClick={() => showDiya(1)}>
                      History
                    </a>
                    <img
                      className={`diya-nav-img ${
                        activeDiya === 1 ? "diya-active" : ""
                      }`}
                      src="/assets/images/diya-navigator.png"
                      alt="Diya-Image"
                      loading="lazy"
                    />
                  </div>
                  <div>
                    <a href="#how-it-works" onClick={() => showDiya(2)}>
                      How it works
                    </a>
                    <img
                      className={`diya-nav-img ${
                        activeDiya === 2 ? "diya-active" : ""
                      }`}
                      src="/assets/images/diya-navigator.png"
                      alt="Diya-Image"
                      loading="lazy"
                    />
                  </div>
                  <div>
                    <a href="#faq" onClick={() => showDiya(3)}>
                      FAQ
                    </a>
                    <img
                      className={`diya-nav-img ${
                        activeDiya === 3 ? "diya-active" : ""
                      }`}
                      src="/assets/images/diya-navigator.png"
                      alt="Diya-Image"
                      loading="lazy"
                    />
                  </div>
                </div>
                <div className="link-container-navigate-stick">
                  <img src="/assets/images/navigator-stick.png" alt="Diya-Stick-Image" loading="lazy"/>
                </div>
              </div>
            </div>
            <div className="details-summary-and-link-container">
              <div className="details-availability" id="puja-summary">
                <div className="summaryhistory">
                  <div className="availability-head summary-container">
                    <h2 className="summary-heading">Summary</h2>
                    <p className="summary-text">{pujaDetails?.description}</p>
                    <img
                      className="summary-underline-img"
                      src="/assets/images/summary-underline.jpg"
                      alt="Underline-Image"
                      loading="lazy"
                    />
                  </div>
                  <h2
                    className="section-heading puja-details-sec puja-procedure-heading"
                    id="puja-procedure"
                  >
                    Puja Procedure
                  </h2>
                  <div className="puja-details-benefits">
                    {pujaDetails?.pujamodels?.[0]?.procedures?.map(
                      (procedure) => {
                        const { title, description, specialNotes } =
                          procedure?.procedure ?? {};
                        return (
                          <>
                            <div className="puja-details-benefits-head">
                              <h4 className="procedure-title"> {title} </h4>
                              <img
                                className="gold-star-dots-procedure"
                                src="/assets/images/gold-star-with-dots.png"
                                alt="Star-Image"
                                loading="lazy"
                              />
                            </div>

                            <div className="procedure-content">
                              <p className="procedure-description">
                                {description}
                              </p>
                              {specialNotes && (
                                <p className="procedure-special-notes">
                                  <span>NOTE:</span> {specialNotes}
                                </p>
                              )}
                            </div>
                          </>
                        );
                      }
                    )}

                    <div className="procedure-note-container"></div>
                    <div className="puja-details-benefits-head">
                      <div className="procedure-note-container"></div>
                      <div className="procedure-bottom-image-div">
                        <img
                          className="procedure-bottom-image"
                          src="/assets/images/procedure-bottom-image.png"
                          alt="Star-Icon"
                          loading="lazy"
                        />
                      </div>
                      <div className="special-notes-sec" id="special-notes">
                        <h2 className="section-heading puja-details-sec">
                          Special Notes
                        </h2>
                        <p>
                          Any specific asks / family traditions / specific needs
                          we need to know
                        </p>
                        <textarea
                          name
                          className="notes-input"
                          placeholder="Please write here"
                          defaultValue={""}
                          onChange={handleNotesChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="history-container" id="history">
                  <h2 className="section-heading">History</h2>
                  <div className="history-inner-container">
                    {videos.length > 0 && (
                      <div>
                        <div className="history-inner-sub-box">
                          <h3 className="history-sub-title">Insight Videos</h3>
                          <img
                            src="/assets/images/gold-star-with-dots.png"
                            alt="Star-Icon"
                            loading="lazy"
                          />
                        </div>
                        <Slider {...settings}>
                          {videos &&
                            videos.map((video, index) => {
                              // Extract video ID from different URL formats

                              const videoId = getVideoId(video);

                              if (!videoId) return null;

                              return (
                                <div
                                  key={index}
                                  onClick={() => handleOpenModal(videoId)}
                                >
                                  <div className="insight-card">
                                    <img
                                      className="video-thumb"
                                      src={`https://img.youtube.com/vi/${videoId}/hqdefault.jpg`}
                                      alt={`Video-${index}`}
                                      loading="lazy"
                                    />
                                  </div>
                                </div>
                              );
                            })}
                        </Slider>

                        {modalVisible && (
                          <div
                            className="modal show"
                            role="dialog"
                            style={{ display: "block" }}
                            aria-modal="true"
                          >
                            <div className="modal-dialog modal-dialog-centered">
                              <div className="modal-content" ref={modalRef}>
                                <Modal
                                  show={modalVisible}
                                  onClose={handleCloseModal}
                                  videoId={selectedVideo}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    {historyData &&
                      historyData.map((item, index) => (
                        <div className="history-inner-sub-box" key={index}>
                          <h3 className="history-sub-title">{item.title}</h3>
                          <img
                            src="/assets/images/gold-star-with-dots.png"
                            alt="Gold-Star-Icon"
                            loading="lazy"
                          />
                          <div>
                            <p>{item.description}</p>
                          </div>
                        </div>
                      ))}
                    <div className="history-image-div">
                      <img src="/assets/images/temple-2.png" alt="Temple-Icon" loading="lazy"/>
                    </div>
                  </div>
                </div>

                <div className="history-container-mobile">
                  <div className="close-icon-div">
                    <h2>History</h2>
                    <img src="/assets/images/close.webp" alt="Close-Icon" loading="lazy"/>
                  </div>
                  <div className="temple-img-container">
                    <img src="/assets/images/temple-2.png" alt="Temple-Image" loading="lazy"/>
                  </div>
                  <div className="history-content-mobile">
                    <div className="history-inner-sub-box">
                      <h3 className="history-sub-title">Benefits</h3>
                      <img
                        src="/assets/images/gold-star-with-dots.png"
                        alt="Star-Icon"
                        loading="lazy"
                      />
                    </div>
                    <p>
                      The shakthi swaroopa that governs everything related to
                      absorption of energy is Saraswathi Devi. Hence this homam
                      has been regularly recommended and utilized for students
                      and children. Some of the common benefits that devotees
                      seek and stand to gain from this homam are: 1. Sharpens
                      skill sets, concentration, memory power, focus and our
                      ability to understand complex things 2. Smooth sailing in
                      all literary pursuits 3. Instill confidence in the
                      individual to achieve great success 4. Attain high
                      intellectual and analytical skills and empower one with
                      high self-esteem thereby helping in attaining positive
                      results. It is also recommended at times by vedic scholars
                      for anyone who has speech/communication-related problems.
                      It also helps in curing defect like stammering by the
                      devi&apos;s grace.
                    </p>
                  </div>
                </div>
                <div className="how-it-works" id="how-it-works">
                  <h2 className="section-heading">How it works</h2>
                  <div className="zig-zag-container">
                    <div className="card">
                      <img
                        src="/assets/images/card-fire.png"
                        className="card-img"
                        alt="Fire-Image"
                        loading="lazy"
                      />
                      <img
                        src="/assets/images/gold-star.png"
                        className="gold-star-point"
                        style={{ top: "-35px" }}
                        alt="Star-Icon"
                        loading="lazy"
                      />
                      <p className="card-text">
                        Once you confirm the booking, our pujari will get in
                        touch to guide you further and finalise the puja plan.
                      </p>
                    </div>
                    <div className="arrow-card-div">
                      <img
                        src="/assets/images/left-curve-arrow.png"
                        className="arrow"
                        alt="Arrow-Icon"
                        loading="lazy"
                      />
                      <div className="card">
                        <img
                          src="/assets/images/gold-star.png"
                          className="gold-star-point"
                          style={{ top: "-64px" }}
                          alt="Star-Icon"
                          loading="lazy"
                        />
                        <p className="card-text">
                          The final plan will be shared with you which includes
                          final cost, muhurtam, samagri, what you need to keep
                          ready at home based on your discussion with the
                          pujari.
                        </p>
                        <img
                          src="/assets/images/card-pray.png"
                          className="card-img"
                          alt="Pray-Image"
                          loading="lazy"
                        />
                      </div>
                    </div>
                    <div className="arrow-card-div">
                      <div className="card">
                        <img
                          src="/assets/images/card-puja.png"
                          className="card-img"
                          alt="Card-Image"
                          loading="lazy"
                        />
                        <img
                          src="/assets/images/gold-star.png"
                          className="gold-star-point"
                          style={{ top: "-50px" }}
                          alt="Star-Icon"
                          loading="lazy"
                        />
                        <p className="card-text">
                          You can pay 10% advance (or) total amount. Once
                          payment is received, the booking is confirmed and
                          pujari time is blocked.
                        </p>
                      </div>
                      <img
                        src="/assets/images/right-curve-arrow.png"
                        className="arrow"
                        alt="Arrow-Image"
                        loading="lazy"
                      />
                    </div>
                  </div>
                </div>
                <div className="faq" id="faq">
                  <h2 className="section-heading">FAQ</h2>
                  <div className="faq-container">
                    <div className="faq-container-inner">
                      <ul className="faq-list">
                        <li>
                          <button
                            className="accordion"
                            onClick={() => toggleFaq(1)}
                          >
                            What happens once I submit the booking?{" "}
                            <img
                              style={{
                                transform:
                                  activeFaq === 1
                                    ? "rotate(180deg)"
                                    : "rotate(0deg)",
                              }}
                              className="faq-arrow-icon"
                              src="/assets/images/down-icon.png"
                              alt="Down-Icon"
                              loading="lazy"
                            />
                          </button>
                          <div
                            className="panel"
                            style={{
                              display: activeFaq === 1 ? "block" : "none",
                            }}
                          >
                            <p className="panel-text">
                              Once you submit the booking, it is assigned to a
                              Karishye pujari who will contact you. He will
                              understand the exact requirements and make the
                              detailed puja plan along with all the steps,
                              samagri and muhurtam (as required). Once you are
                              satisfied with the plan and make the payment the
                              puja is confirmed and the pujari time is blocked
                            </p>
                          </div>
                        </li>
                      </ul>
                      <ul className="faq-list">
                        <li>
                          <button
                            className="accordion"
                            onClick={() => toggleFaq(2)}
                          >
                            Do I need to pay in advance or only after the puja
                            is completed?{" "}
                            <img
                              style={{
                                transform:
                                  activeFaq === 2
                                    ? "rotate(180deg)"
                                    : "rotate(0deg)",
                              }}
                              className="faq-arrow-icon"
                              src="/assets/images/down-icon.png"
                              alt="Drop-Down"
                              loading="lazy"
                            />
                          </button>
                          <div
                            className="panel"
                            style={{
                              display: activeFaq === 2 ? "block" : "none",
                            }}
                          >
                            <p className="panel-text">
                              We ask for a partial advance payment. This is
                              mainly for us to arrange for samagri or to ensure
                              that we are blocking the pujari time. Once the
                              puja is completed the remaining balance can be
                              paid You also have an option to make the full
                              payment in advance as well. Rest assured, if you
                              cancel in advance we will return all the amount.
                            </p>
                          </div>
                        </li>
                      </ul>
                      <ul className="faq-list">
                        <li>
                          <button
                            className="accordion"
                            onClick={() => toggleFaq(3)}
                          >
                            What if I want to cancel my booking?{" "}
                            <img
                              style={{
                                transform:
                                  activeFaq === 3
                                    ? "rotate(180deg)"
                                    : "rotate(0deg)",
                              }}
                              className="faq-arrow-icon"
                              src="/assets/images/down-icon.png"
                              alt="Down-Icon"
                              loading="lazy"
                            />
                          </button>
                          <div
                            className="panel"
                            style={{
                              display: activeFaq === 3 ? "block" : "none",
                            }}
                          >
                            <p className="panel-text">
                              Please contact us on our whatsapp number
                              +91-8121007481 . We will cancel your booking from
                              our backend and arrange for the refund. Please
                              note: Cancellations made 3 days or more in advance
                              of the puja start date will receive a 100% refund.
                              Cancellations made between 3 days to 1 day before
                              puja will receive a 50% refund. Cancellations made
                              within 24 hours will not receive a refund because
                              we would have already made the required
                              arrangements and our pujari would have not
                              accepted any other pujas during that time.
                            </p>
                          </div>
                        </li>
                      </ul>
                      <ul className="faq-list">
                        <li>
                          <button
                            className="accordion"
                            onClick={() => toggleFaq(4)}
                          >
                            How to reschedule my puja? My plans have changed due
                            to unforeseen circumstances.{" "}
                            <img
                              style={{
                                transform:
                                  activeFaq === 4
                                    ? "rotate(180deg)"
                                    : "rotate(0deg)",
                              }}
                              className="faq-arrow-icon"
                              src="/assets/images/down-icon.png"
                              alt="Down-Icon"
                              loading="lazy"
                            />
                          </button>
                          <div
                            className="panel"
                            style={{
                              display: activeFaq === 4 ? "block" : "none",
                            }}
                          >
                            <p className="panel-text">
                              Please contact us on our whatsapp number
                              +91-8121007481 . We will reschedule your booking
                              from our backend (generally at no extra cost).
                              Please note, when we reschedule we will have to
                              check with the pujari&apos;s availability and
                              accordingly provide the best possible date
                              options. Please support and bear with us.
                            </p>
                          </div>
                        </li>
                      </ul>
                      <ul className="faq-list">
                        <li>
                          <button
                            className="accordion"
                            onClick={() => toggleFaq(5)}
                          >
                            Important: Pujari changes in the last minute{" "}
                            <img
                              style={{
                                transform:
                                  activeFaq === 5
                                    ? "rotate(180deg)"
                                    : "rotate(0deg)",
                              }}
                              className="faq-arrow-icon"
                              src="/assets/images/down-icon.png"
                              alt="Down-Icon"
                              loading="lazy"
                            />
                          </button>
                          <div
                            className="panel"
                            style={{
                              display: activeFaq === 5 ? "block" : "none",
                            }}
                          >
                            <p className="panel-text">
                              Generally at Karishye, we ensure that the same
                              pujari who has been assigned to you in the plan
                              comes for the puja. However in cases where we
                              cannot avoid : such as pujari falling sick etc. we
                              would have to re assign the puja to a new pujari.
                              This will be communicated to you by our team as
                              soon as we come to know about it.. However, rest
                              assured that any pujari we send will perform the
                              procedure properly.{" "}
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="faq-container-mobile">
                    <div className="faq-container-mobile-inner">
                      <div className="close-icon-div">
                        <h2>FAQ</h2>
                        <img src="/assets/images/close.webp" alt="Close-Image" loading="lazy"/>
                      </div>
                      <hr />
                      <div className="faq-container-inner">
                        <ul className="faq-list">
                          <li>
                            <button className="accordion">
                              What happens once I submit the booking?
                              <img
                                className="faq-arrow-icon"
                                src="/assets/images/down-icon.png"
                                alt="Down-Arrow-Icon"
                                loading="lazy"
                              />
                            </button>
                            <div className="panel">
                              <p>
                                Once you submit the booking, it is assigned to a
                                Karishye pujari who will contact you. He will
                                understand the exact requirements and make the
                                detailed puja plan along with all the steps,
                                samagri and muhurtam (as required). Once you are
                                satisfied with the plan and make the payment the
                                puja is confirmed and the pujari time is blocked
                              </p>
                            </div>
                          </li>
                          <li>
                            <button className="accordion">
                              Do I need to pay in advance or only after the puja
                              is completed?{" "}
                              <img
                                className="faq-arrow-icon"
                                src="/assets/images/down-icon.png"
                                alt="Down-Icon"
                                loading="lazy"
                              />
                            </button>
                            <div className="panel">
                              <p>
                                We ask for a partial advance payment. This is
                                mainly for us to arrange for samagri or to
                                ensure that we are blocking the pujari time.
                                Once the puja is completed the remaining balance
                                can be paid You also have an option to make the
                                full payment in advance as well. Rest assured,
                                if you cancel in advance we will return all the
                                amount.
                              </p>
                            </div>
                          </li>
                          <li>
                            <button className="accordion">
                              What if I want to cancel my booking?{" "}
                              <img
                                className="faq-arrow-icon"
                                src="/assets/images/down-icon.png"
                                alt="Down-Arrow-Icon"
                                loading="lazy"
                              />
                            </button>
                            <div className="panel">
                              <p>
                                Please contact us on our whatsapp number
                                +91-8121007481 . We will cancel your booking
                                from our backend and arrange for the refund.
                                Please note: Cancellations made 3 days or more
                                in advance of the puja start date will receive a
                                100% refund. Cancellations made between 3 days
                                to 1 day before puja will receive a 50% refund.
                                Cancellations made within 24 hours will not
                                receive a refund because we would have already
                                made the required arrangements and our pujari
                                would have not accepted any other pujas during
                                that time.
                              </p>
                            </div>
                          </li>
                          <li>
                            <button className="accordion">
                              How to reschedule my puja? My plans have changed
                              due to unforeseen circumstances.{" "}
                              <img
                                className="faq-arrow-icon"
                                src="/assets/images/down-icon.png"
                                alt="Down-Icon"
                                loading="lazy"
                              />
                            </button>
                            <div className="panel">
                              <p>
                                Please contact us on our whatsapp number
                                +91-8121007481 . We will reschedule your booking
                                from our backend (generally at no extra cost).
                                Please note, when we reschedule we will have to
                                check with the pujari&apos;s availability and
                                accordingly provide the best possible date
                                options. Please support and bear with us.
                              </p>
                            </div>
                          </li>
                          <li>
                            <button className="accordion">
                              Important: Pujari changes in the last minute{" "}
                              <img
                                className="faq-arrow-icon"
                                src="/assets/images/down-icon.png"
                                alt="Down-Icon"
                                loading="lazy"
                              />
                            </button>
                            <div className="panel">
                              <p>
                                Generally at Karishye, we ensure that the same
                                pujari who has been assigned to you in the plan
                                comes for the puja. However in cases where we
                                cannot avoid : such as pujari falling sick etc.
                                we would have to re assign the puja to a new
                                pujari. This will be communicated to you by our
                                team as soon as we come to know about it..
                                However, rest assured that any pujari we send
                                will perform the procedure properly.{" "}
                              </p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="note-sec">
                  <p>
                    <span className="note-bold">Disclaimer: </span>Karishye
                    presents its view on puja and procedure as per it&apos;s
                    research and understanding. We do not claim that our views
                    are the only correct ones.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <hr className="something-else-divider"></hr>
          <div className="details-bottom-sec">
            <div className="something-else-container">
              <h2>Looking for something else ?</h2>
              <div className="carousel-container">
                {pujaList?.map((puja, index) => (
                  <div
                    key={index}
                    className="puja-card"
                    onClick={() => {
                      let navigateTo = puja?.url_name;
                      window.open(navigateTo, "_self");
                    }}
                  >
                    <a className="puja-card-line">
                      <img
                        src={
                          `${process.env.REACT_APP_IMAGE_URL_317_166}/${puja?.url_name?.trim()}.webp`}
                        alt={`${puja.name}-image`}
                        className="puja-image-full tw-w-[300px] tw-h-[166px]"
                        onError={handleImageError}
                        loading="lazy"
                      />
                      <h4 className="puja-title"> {puja.name}</h4>
                      <p className="puja-desc">{puja.description}</p>
                      <img
                        className="gold-line"
                        src="/assets/images/gold-line.webp"
                        alt="Gold-Line-Image"
                        loading="lazy"
                      />
                    </a>
                    <div>
                      <button className="puja-book-button">
                        <a className="offerings-pujas">Know More</a>
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="samagri-outer-container"
        style={{ display: showSamagri ? "flex" : "none" }}
      >
        <div className="container samagri-container">
          <div className="samagri-box">
            <div className="samagri-header" id="samagri-header">
              <h2 className="section-heading">Samagri</h2>
              <img
                src="/assets/images/close.webp"
                alt="Close-Image"
                onClick={() => hideSamagri()}
                loading="lazy"
              />
            </div>
            <div className="samagri-box-inner">
              <div className="samagri-items-container">
                <p className="samagri-heading">
                  Samagri provided by Karishye (Already included in package)
                </p>
                <img
                  className="gold-star-line"
                  src="/assets/images/gold-star-with-line.png"
                  alt="Gold-Star"
                  loading="lazy"
                />
                <img
                  className="gold-star-dots"
                  src="/assets/images/gold-star-with-dots.png"
                  alt="Gold-Star"
                  loading="lazy"
                />

                <div className="samagri-items">
                  <ul>
                    {defaultSamagri.map((puja, index) => (
                      <li key={index}>
                        {" "}
                        <span className="samagri-item-name">
                          {" "}
                          {puja.name}
                        </span>{" "}
                        <span className="samagri-item-quantity">
                          {calculateSamagriQuantity(
                            puja?.standardQty,
                            puja?.qty,
                            puja.units
                          )}
                        </span>{" "}
                      </li>
                    ))}
                  </ul>
                  <div className="separte_line"></div>
                </div>
              </div>

              <div className="samagri-you-can-also-buy" id="additional-samagri">
                <div className="additional-samagri-heading-container">
                  <p className="samagri-heading">Add-ons</p>
                  <img
                    className="gold-star-line"
                    src="/assets/images/gold-star-with-line.png"
                    alt="Gold-Star"
                    loading="lazy"
                  />
                  <img
                    className="gold-star-dots"
                    src="/assets/images/gold-star-with-dots.png"
                    alt="Gold-Star"
                    loading="lazy"
                  />
                  {addonSamagri && addonSamagri.length > 0 && (
                    /**
                     * Removed the table if their are no addonsamagri.
                     */
                    <div>
                      <ul className="you-can-buy-list">
                        <li>
                          <p>Items</p>
                          <p>Quantity</p>
                          <p>Price</p>
                          <p>Add</p>
                        </li>
                      </ul>
                      {addonSamagri?.map((puja, index) => (
                        <ul className="you-can-buy-list" key={index}>
                          <li>
                            <p className="samagri-item-name-col">
                              {puja?.name}
                              <span className="samagri-desc-span">
                                {puja?.description}
                              </span>
                            </p>
                            <p>
                              {puja?.standardQty * puja?.qty} {puja?.units}
                            </p>
                            <p className="buy-item-price">
                              ₹ {puja?.customerMrp * puja?.qty}
                            </p>
                            {toggleSamagri[puja.id] ? (
                              <button
                                className="remove-button"
                                onClick={() => removeSamagri(puja)}
                              >
                                Remove
                              </button>
                            ) : (
                              <button
                                className="add-button"
                                onClick={() => addSamagri(puja)}
                              >
                                Add
                              </button>
                            )}
                          </li>
                        </ul>
                      ))}
                    </div>
                  )}
                </div>
              </div>

              <div className="samagri-what-you-need">
                <p className="samagri-heading">Items to keep ready at home</p>
                <img
                  className="gold-star-line"
                  src="/assets/images/gold-star-with-line.png"
                  alt="Gold-Star"
                  loading="lazy"
                />
                <img
                  className="gold-star-dots"
                  src="/assets/images/gold-star-with-dots.png"
                  alt="Gold-Star"
                  loading="lazy"
                />

                <div className="samagri-what-you-need-items">
                  <ul>
                    {userSamagri.map((puja, index) => (
                      <li key={index}>
                        <img src="/assets/images/gold-star.png" loading="lazy" alt="Gold-Star-Icon"/>
                        <span className="samagri-item-name"> {puja.name}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="all-items-checkbox">
                <div className="samagri-done">
                  <p className="donebtn" onClick={() => hideSamagri()}>
                    Done
                  </p>
                </div>
              </div>
            </div>

            <div className="samagri-bottom-image-div">
              <img
                src="/assets/images/special-notes-icon.png"
                className="special-notes-img"
                alt="Notes-Icon"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </div>
      <LoginModal
        title ={"Finalize Your Booking"}
        PujaName={`${pujaDetails.url_name}`}
        data={data}
        signupFormData={signupFormData}
        frombooking={true}
        setLoginModal={setLoginModal}
        loginModal={loginModal}
        style={{ display: loginModal ? "flex" : "none" }}
      />
      <Footer />
      <Whatsapp />
    </>
  );
}
