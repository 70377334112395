import React from 'react'
import "./Whatsapp.scss";
import { logEvent } from "../../Utils/Analytics";
import { EVENT_CATEGORIES, EVENT_ACTIONS, EVENT_LABELS } from "../../Constants/myDharmaContent";
function Whatsup() {
  const handleWhatsAppClick = () => {
    logEvent(EVENT_CATEGORIES.ENQUIRY,EVENT_ACTIONS.WHATSAPP, EVENT_LABELS.CLICK);
  };
  return (
    <a onClick={handleWhatsAppClick} href="https://wa.me/+918121007481" target='_blank' rel="noreferrer" className='contacticon'>
    <img className="whatsup" src='../../assets/images/whatappbusinessfooter.png' alt='whatsapp-business' loading="lazy"/>
</a>
  )
}
export default Whatsup
