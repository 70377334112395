import axiosInstance from './AxiosInstance';
import {debounce} from "../Utils/Debounce";

const CACHE_KEY = 'puja_cache_';
const CACHE_DURATION = 15 * 60 * 1000; // 15 minutes

const getCachedData = (key) => {
  const cached = localStorage.getItem(`${CACHE_KEY}${key}`);
  if (cached) {
    const { data, timestamp } = JSON.parse(cached);
    if (Date.now() - timestamp < CACHE_DURATION) {
      return data;
    }
  }
  return null;
};

const setCachedData = (key, data) => {
  localStorage.setItem(`${CACHE_KEY}${key}`, JSON.stringify({
    data,
    timestamp: Date.now()
  }));
};

export const PujaService = {
  getPujaDetails: debounce (async(pujaId) => {
    try {
      const cacheKey = `puja_${pujaId}`;
      const cachedData = getCachedData(cacheKey);
      if (cachedData) return cachedData;

      const response = await axiosInstance.get(`/pujas?id=${pujaId}`);
      const data = response.data.data[0];
      setCachedData(cacheKey, data);
      return data;
    } catch (error) {
      console.error('Error fetching puja details:', error);
      throw error;
    }
  },300),

  getSamagriList: debounce (async (pujaId) => {
    try {
      const cacheKey = `samagri_${pujaId}`;
      const cachedData = getCachedData(cacheKey);
      if (cachedData) return cachedData;

      const response = await axiosInstance.get(`/puja-samagri/${pujaId}`);
      const data = response.data.data;
      setCachedData(cacheKey, data);
      return data;
    } catch (error) {
      console.error('Error fetching samagri list:', error);
      throw error;
    }
  },300),

  getAllPujas: debounce (async() => {
    try {
      const cacheKey = 'all_pujas';
      const cachedData = getCachedData(cacheKey);
      if (cachedData) return cachedData;

      const response = await axiosInstance.get('/all-pujas');
      const data = response;
      console.log("data", data);
      setCachedData(cacheKey, data);
      return data;
    } catch (error) {
      console.error('Error fetching all pujas:', error);
      throw error;
    }
  },300),

  getPujaList: debounce (async() => {
    try {
      const cacheKey = 'puja_list';
      const cachedData = getCachedData(cacheKey);
      if (cachedData) return cachedData;

      const response = await axiosInstance.get('/pujas');
      const data = response.data.data.map(item => ({
        ...item,
        pujas: item
      }));
      setCachedData(cacheKey, data);
      return data;
    } catch (error) {
      console.error('Error fetching puja list:', error);
      throw error;
    }
  },300),

  getPujaHistory: debounce (async() => {
    try {
      const cacheKey = 'puja_history';
      const cachedData = getCachedData(cacheKey);
      if (cachedData) return cachedData;

      const response = await axiosInstance.get('/puja-history');
      const data = response.data.data;
      setCachedData(cacheKey, data);
      return data;
    } catch (error) {
      console.error('Error fetching puja history:', error);
      throw error;
    }
  },300),

  getRelatedPujas: debounce (async(categoryId) => {
    try {
      const cacheKey = `related_pujas_${categoryId}`;
      const cachedData = getCachedData(cacheKey);
      if (cachedData) return cachedData;

      const response = await axiosInstance.get(`/pujas?categoryId=${categoryId}`);
      const data = response.data.data.slice(0, 3);
      setCachedData(cacheKey, data);
      return data;
    } catch (error) {
      console.error('Error fetching related pujas:', error);
      throw error;
    }
  },300),
};