import { Button, Title, Img, Text } from "../../Component";
import React from "react";
import { useNavigate } from "react-router-dom"; 

export default function SanatanaDharmaSection() {


  const navigate = useNavigate();

  return (
    <>
      {/* sanatana dharma section */}
      <div>
        <div className="tw-flex  tw-items-center tw-justify-center tw-bg-cover tw-bg-no-repeat  md:tw-h-auto md:tw-py-5 md:tw-mt-[60px] sm:tw-mt-[20px]">
          <div className="container tw-flex tw-justify-center md:tw-px-5">{/*adjusted the alignment */}
            <div className="tw-flex tw-w-full tw-flex-col tw-items-center tw-text-center md:tw-px-5">{/*adjusted the alignment */}
              <Text as="p" className="tw-text-[70px] tw-font-normal tw-text-primary_maroon md:tw-text-[48px] md:tw-text-center sm:tw-text-[34px]">
                True meaning of Sanatana Dharma
              </Text>
              <div className="tw-flex tw-w-[50%] tw-items-center tw-flex-col tw-gap-12 md:tw-w-full sm:tw-gap-[0px]">{/* adjusted the text size */}
                <Img src="/assets/images/sanatana_dharma_image.webp" alt="Dharma-Image" className="tw-h-[484px] tw-object-cover sm:tw-h-[279px]" loading="lazy"/>
                <div className="tw-mx-[38px] tw-flex tw-flex-col tw-items-center tw-gap-5 md:tw-mx-0">
                  <Title
                    size="texts"
                    as="h2"
                    className="tw-text-center tw-text-[14px] tw-font-medium tw-leading-6 tw-tracking-[0.14px] tw-text-gray-900"
                  >
                    <>
                      God is not partial towards those who shower him with praise or offerings. <br />
                      Divine force comes searching for those who follow Dharma.
                    </>
                  </Title>
                  <Button
                    size="md"
                    variant="fill"
                    shape="square"
                    className="tw-bg-white tw-bg-opacity-100 md:tw-self-stretch tw-px-[34px] tw-w-[243px] md:tw-w-full tw-items-center tw-font-quicksand tw-font-medium tw-tracking-[0.16px] md:tw-mx-0 sm:tw-px-5"
                    onClick={() => navigate("/mydharma")}
                  >{/*adjusted the alignment */}
                    Join my Dharma
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
