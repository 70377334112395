import { Text } from "../../Component";
import React, { useState, useEffect } from "react";
import PujaDetail from "../../Component/PujaDetail";
import Loader from "../../Component/Loader/Loader";

export default function CardsSection({ pujas }) {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (pujas && pujas.length > 0) {
      setIsLoading(false);
    }
  }, [pujas]);

  const PujaDetailList = ({ pujasList }) => {
    return pujasList.map((puja, index) => (
      <PujaDetail key={`pujaDetail-${index}`} pujas={puja} titleClassName="tw-h-[68px]" />
    ));
  };

  let pujaCategoryNames = localStorage.getItem("tab");
  if (!pujaCategoryNames) {
    pujaCategoryNames = "All Pujas";
  }

  return (
    <>
      {isLoading ? (
        <Loader fullScreen />
      ) : (
        <div>
          <div className="container tw-flex tw-flex-col tw-items-center tw-justify-center tw-bg-[url(/public/assets/images/background_image1.webp)] tw-bg-cover tw-bg-no-repeat tw-py-[72px] md:tw-h-auto md:tw-py-5">
            <div className="container tw-mb-1.5 md:tw-px-5">
              <div className="tw-flex tw-flex-col tw-items-center tw-gap-5">
                <Text
                  size="text2xl"
                  as="p"
                  className="tw-text-[40px] tw-font-normal tw-text-primary_maroon md:tw-text-[38px] sm:tw-text-[36px]"
                >
                  {pujaCategoryNames}
                </Text>
                <div className="tw-grid tw-grid-cols-3 tw-justify-center tw-gap-10 tw-self-stretch md:tw-grid-cols-2 sm:tw-grid-cols-1">
                  <PujaDetailList pujasList={pujas} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}