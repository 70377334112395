import { Title, Button, Slider, Text } from "../../Component";
import ReactPlayer from "react-player";
import React, { useRef, useState } from "react";
import { VIDEO_CONTENT } from "../../Constants/myDharmaContent";

export default function DharmaOfferingsSection() {
  const [sliderState, setSliderState] = useState(0);
  const sliderRef = useRef(null);

  const handleSlideChange = (e) => {
    setSliderState(e?.item);
  };

  const handleDotClick = (index) => {
    sliderRef?.current?.slideTo(
      index * (sliderRef?.current?.state?.itemsInSlide || 1)
    );
  };

  return (
    <>
      <div>
        <div className="tw-flex tw-h-[1018px] tw-items-center tw-justify-center tw-bg-[url('/public/assets/images/background_image1.webp')] tw-bg-cover tw-bg-no-repeat tw-py-20 md:tw-h-auto md:tw-py-5">
          <div className="container tw-flex tw-justify-center md:tw-px-5">
            <div className="tw-flex tw-w-full tw-flex-col tw-items-center tw-gap-12 sm:tw-gap-1">{/*adjusted the alignment */}
              <div className="tw-mx-[216px] tw-flex tw-flex-col tw-items-center tw-gap-2.5 tw-self-stretch md:tw-mx-0"> {/*changed the styling*/}
                <Text
                  as="p"
                  className="tw-text-center tw-text-[70px] tw-font-normal tw-leading-[96px] tw-text-primary_maroon md:tw-text-[30px] md:tw-leading-normal sm:tw-text-[30px] sm:tw-mt-[40px]"
                >
                  <>
                    What made us create the
                    <br />
                    My Dharma App?
                  </>
                </Text>
                <Title
                  size="texts"
                  as="h2"
                  className="tw-self-stretch tw-text-center tw-text-[14px] tw-font-medium tw-leading-6 tw-tracking-[0.14px] tw-text-gray-900"
                >
                  <>
                    Divine grace comes searching for those who are in tune with
                    their Dharma. There was a time when we had 6 lakh active
                    gurukuls showing us the way. Let us now use technology, and
                    together build back the bridges.
                    <br />
                    <br />
                    The SINGLE greatest service we can do to Sanatana Dharma is:
                    Practice it, Understand it & Inspire others
                  </>
                </Title>
              </div>
              <div className="tw-mx-auto tw-flex tw-w-[50%] md:tw-w-full sm:tw-w-full">
                <Slider
                  responsive={{
                    0: { items: 1 },
                    551: { items: 1 },
                    1051: { items: 1 },
                  }}
                  disableDotsControls
                  activeIndex={sliderState}
                  onSlideChanged={handleSlideChange}
                  ref={sliderRef}
                  swipeable={false}
                  draggable={false}
                  preventEventBubbling
                  disableButtonsControls={false}
                  className="tw-select-none"
                  items={VIDEO_CONTENT.map((video, index) => (
                    <React.Fragment key={index}>
                      <div className="tw-flex tw-gap-10 md:tw-flex-col tw-select-none">
                        <div className="tw-flex tw-w-full tw-flex-col tw-gap-3.5">
                          <div className="tw-flex tw-h-[332px] tw-flex-col tw-items-center tw-gap-[88px] tw-border-[20px] tw-border-solid tw-border-white-a700 tw-bg-cover tw-bg-no-repeat tw-p-1.5 tw-shadow-[0_4px_6px_rgba(0,0,0,0.1)] md:tw-gap-[66px] sm:tw-gap-11 sm:tw-h-[283px] sm:tw-shadow-none">
                            <ReactPlayer
                              url={video.url}
                              width="100%"
                              height="100%"
                              controls
                              className="tw-top-0 tw-left-0"
                            />
                          </div>
                          <div className="tw-flex tw-flex-col tw-items-center tw-gap-0.5">
                            <Title
                              size="textlg"
                              as="h4"
                              className="tw-text-[24px] tw-font-medium tw-text-gray-900 md:tw-text-[22px]"
                            >
                              {video.title}
                            </Title>
                            <Title
                              size="texts"
                              as="h5"
                              className="tw-w-full tw-text-[14px] tw-text-center tw-font-medium tw-leading-6 tw-tracking-[0.14px] tw-text-gray-900 sm:tw-line-clamp-2"
                            >
                              {video.description}
                            </Title>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
                />
              </div>
              {VIDEO_CONTENT.length > 1 && ( //added the condition to check if the length of the video content is greater than 1
                <div className="tw-flex tw-items-center tw-justify-center md:tw-hidden">
                  {[...Array(VIDEO_CONTENT.length)].map((_, i) => (
                    <div key={i}>
                      {sliderState >=
                        i * (sliderRef?.current?.state?.itemsInSlide || 1) &&
                      sliderState <
                        (i + 1) *
                          (sliderRef?.current?.state?.itemsInSlide || 1) ? (
                        <div
                          onClick={() => handleDotClick(i)}
                          className="tw-mr-1.5 tw-inline-block tw-h-[6px] tw-w-[38px] tw-cursor-pointer tw-rounded-[3px] tw-bg-primary_maroon"
                        />
                      ) : (
                        <div
                          onClick={() => handleDotClick(i)}
                          className="tw-mr-1.5 tw-inline-block tw-h-[6px] tw-w-[20px] tw-cursor-pointer tw-rounded-[3px] tw-border tw-border-solid tw-border-primary_maroon"
                        />
                      )}
                    </div>
                  ))}
                </div>
              )}
              <Button
                variant="fill"
                shape="square"
                className="tw-hidden tw-border-solid md:tw-block sm:tw-block tw-w-[100%] tw-h-[50px] tw-self-end tw-px-[34px] tw-font-quicksand tw-font-medium tw-tracking-[0.16px] sm:tw-self-auto sm:tw-px-5"
                onClick={() =>
                  window.open("https://www.youtube.com/@karishye", "_blank")  //opens in a new tab
                }
              >
                See more
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
