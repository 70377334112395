import { Img, Text } from "../../Component";
import React from "react";

export default function PreservationSection() {
  return (
    <>
      {/* preservation section */}
      <div className="tw-flex tw-justify-center md:tw-mt-[60px]">
        <div className="container tw-flex tw-items-center tw-justify-center md:tw-flex-col md:tw-px-5">{/*adjusted the alignment */}
          <div className="tw-flex tw-flex-1 tw-items-start tw-gap-5 md:tw-flex-col md:tw-self-stretch sm:tw-order-2">
            <Img
              src="/assets/images/quote_image.webp"
              alt="Quote-Image"
              className="tw-h-[76px] tw-w-[16%] tw-object-contain md:tw-w-full md:tw-hidden"
              loading="lazy"
            />
            <div className="tw-flex tw-flex-row tw-flex-start sm:tw-flex-col">
            <Img
              src="/assets/images/quote_image.webp"
              alt="Quote-Image"
              className="tw-hidden tw-h-[76px] tw-w-[16%] tw-object-contain md:tw-w-[20%] md:tw-block"
              loading="lazy"
            />
            <Text
              size="textxl"
              as="p"
              className="tw-text-primary_maroon tw-font-playfairdisplay md:tw-w-[84%] sm:tw-w-[100%] tw-self-center tw-text-[36px] tw-font-medium tw-leading-[56px] tw-text-primary_maroon md:tw-w-full md:tw-text-center md:tw-text-[34px] sm:tw-text-[24px] sm:tw-text-start sm:tw-leading-[30px]"    // Adjusted the text size
            >
              Preserving Sanatana Dharma is the greatest favour we can do to ourselves
            </Text>
            </div>
          </div>
          <Img
            src="/assets/images/tree_image.webp"
            alt="Tree-Image"
            className="tw-h-[516px] tw-w-[50%] tw-object-contain md:tw-w-full sm:tw-order-1 sm:tw-h-[250px]"
            loading="lazy"
          />
        </div>
      </div>
    </>
  );
}
