import React from "react";
import "./Footer.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EVENT_CATEGORIES,EVENT_ACTIONS,EVENT_LABELS,BUTTON_LABELS } from "../../Constants/myDharmaContent";
import {
  faYoutube,
  faInstagram,
  faLinkedin,
  faFacebook,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { logEvent } from "../../Utils/Analytics";
export default function Footer() {
  const user = localStorage.getItem("userId");
  const handleLogEvent = () => {
    logEvent(EVENT_CATEGORIES.EXPLORE, EVENT_ACTIONS.BOOK_PUJA_BUTTON, EVENT_LABELS.CLICK);
  };
  return (
    <>
      <div className="footersec">
        <div className="topftrsec">
          <div className="container">
            <a href="/offerings" className="bookbut" role="button" onClick={handleLogEvent}>
              <div className="widget_text widget widget_custom_html">
                <div className="textwidget custom-html-widget">{BUTTON_LABELS.BOOK_NOW}</div>
              </div>
            </a>
            <span>
              <img src="/assets/images/line03.png" alt="Footer-Border-Line" width="100%" loading="lazy"/>
            </span>
          </div>
        </div>
        <div className="midftrsec">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-4">
                <div className="ftrlinks">
                  <h2>Quick links</h2>
                  <ul>
                    <li
                      id="menu-item-665"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-665"
                    >
                      <a href="/offerings" role="button" onClick={handleLogEvent}>{BUTTON_LABELS.BOOK_NOW}</a>
                    </li>
                    {user && (
                      <li
                        id="menu-item-732"
                        className="log-in-booking menu-item menu-item-type-post_type menu-item-object-page menu-item-732"
                      >
                        <a href="/mybookings">My Bookings</a>
                      </li>
                    )}
                    <li
                      id="menu-item-731"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-731"
                    >
                      <a href="/offerings">Our Offerings</a>
                    </li>
                    <li
                      id="menu-item-32"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-32"
                    >
                      <a href="/pujaris">Who is a Pujari?</a>
                    </li>
                    <li
                      id="menu-item-30"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-30"
                    >
                      <a href="/about">About Us</a>
                    </li>
                    <li
                      id="menu-item-352"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-352"
                    >
                      <a href="/contact">Contact Us</a>
                    </li>
                  </ul>{" "}
                </div>
              </div>
              <div className="col-lg-6 col-md-4">
                <div className="ftrlinks sndftrlinks">
                  <h2>Help</h2>
                  <ul>
                    <li
                      id="menu-item-244"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-244"
                    >
                      <a href="/terms-conditions">Terms &amp; Conditions</a>
                    </li>
                    <li
                      id="menu-item-245"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-245"
                    >
                      <a href="/refund-cancellation-policy">
                        Refund &amp; Cancellation Policy
                      </a>
                    </li>
                    <li
                      id="menu-item-246"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-privacy-policy menu-item-246"
                    >
                      <a rel="privacy-policy" href="/privacy-policy">
                        Privacy Policy
                      </a>
                    </li>
                  </ul>{" "}
                </div>
              </div>
              <div className="col-lg-3 col-md-4">
                <div className="ftrlinklastcol">
                  <div className="sociallinksec">
                    <p>Social Links</p>
                    <ul>
                      <li>
                        <a
                          href="https://www.youtube.com/channel/UCF7bn_6nxpnQQn3OMJ-XfMQ/featured"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FontAwesomeIcon icon={faYoutube} size="1x" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/karishye.co/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FontAwesomeIcon icon={faInstagram} size="1x" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.facebook.com/Karishye-101730805811532/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FontAwesomeIcon icon={faFacebook} size="1x" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://twitter.com/hello_karishye"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FontAwesomeIcon icon={faTwitter} size="1x" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.linkedin.com/company/karishye"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FontAwesomeIcon icon={faLinkedin} size="1x" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="botftrsec">
          <div className="container">
            <div className="widget_text widget widget_custom_html">
              <div className="widget_text widget-content">
                <div className="textwidget custom-html-widget">
                  <p>
                    Copyright ™ 2024 Karishye Private
                    Limited - All Rights Reserved.
                  </p>
                </div>
              </div>
            </div>{" "}
          </div>
        </div>
      </div>
    </>
  );
}
