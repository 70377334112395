import { Img, Text } from "../../Component";
import InformationalTextSection from "../../Component/InformationalTextSection";
import React, { Suspense } from "react";
import {PUJA_EXPERIENCE_DATA} from "../../Constants/myDharmaContent";

export default function KarishyePujaExperienceSection() {
  return (
    <>
      {/* karishye puja experience section */}
      <div>
        <div className="tw-mt-[50px] tw-flex tw-items-center tw-justify-center tw-bg-[url(/public/assets/images/background_image1.webp)] tw-bg-cover tw-bg-no-repeat md:tw-h-auto md:tw-py-5 md:tw-mt-60px">   {/* adjusted the margin top */}
          <div className="container tw-flex tw-justify-center md:tw-px-5">{/*adjusted the alignment */}
            <div className="tw-flex tw-w-full tw-flex-col tw-items-center tw-gap-[30px] tw-bg-white-a700 tw-p-[50px] tw-shadow-xs md:tw-shadow-none md:tw-p-5 md:tw-bg-transparent sm:tw-gap-[0px] sm:tw-mt-10px">
              <Text as="p" className="tw-text-[70px] tw-font-normal tw-text-primary_maroon md:tw-text-[48px] sm:tw-text-[34px] sm:tw-text-center">
                Karishye Puja Experience
              </Text>
              <Img src="/assets/images/homam_image.webp" alt="Puja-Image" className="tw-h-[340px] tw-w-[32%] tw-object-contain md:tw-w-[100%] sm:tw-h-[250px]" loading="lazy"/>
              <div className="tw-flex tw-gap-[62px] tw-self-stretch md:tw-flex-col md:tw-gap-42px sm:tw-gap-[30px]"
              style={{
                alignItems: "center",
              }}>
                <Suspense fallback={<div>Loading feed...</div>}>
                  {PUJA_EXPERIENCE_DATA.map((d, index) => (
                    <InformationalTextSection {...d} key={"whyList" + index} />
                  ))}
                </Suspense>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
