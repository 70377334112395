import { Img, Title } from "./..";
import React from "react";
import { logEvent } from "../../Utils/Analytics";
import { BsFillTelephoneFill } from "react-icons/bs";
import { EVENT_CATEGORIES, EVENT_ACTIONS, EVENT_LABELS } from "../../Constants/myDharmaContent";
export default function Header({ ...props }) {
  const UserId = localStorage.getItem("userId");   // Get user id from local storage
  const handlePhoneClick = () => {
    logEvent(EVENT_CATEGORIES.ENQUIRY, EVENT_ACTIONS.PHONE_CALL, EVENT_LABELS.CLICK);
  };
  const handleBookPujaClick = () => {
    logEvent(EVENT_CATEGORIES.EXPLORE, EVENT_ACTIONS.BOOK_PUJA_BUTTON, EVENT_LABELS.CLICK);
  };
  return (
    <header
      {...props}
      className={`${props.className} tw-flex tw-justify-center tw-items-center tw-ml-[18px] tw-mr-6`}  //fixed the alignment issues
    >
      <Img
        src="/assets/images/logo.png"
        loading="lazy"
        alt="Logo-Image"
        className="tw-h-[60px] sm:tw-w-[30%] tw-object-contain tw-cursor-pointer"
        onClick={() => window.location.replace("/")}     // Redirect to home page on logo click
      />
      <div className="tw-ml-5 tw-h-px tw-flex-1 tw-bg-gradient md:tw-m-[1.5rem] sm:tw-mx-[1rem] tw-mt-[15px]" />
      <div className="tw-ml-[18px] tw-flex tw-self-end md:tw-ml-0 sm:tw-self-auto">
        <ul className="tw-flex tw-flex-wrap tw-gap-5">
          <li>  
            <a>
              <span className="md:tw-hidden tw-cursor-default"><BsFillTelephoneFill/></span>
              <div className="tw-hidden md:tw-block md:tw-m-[1.5rem]" role="button" onClick={() => {(window.location.href = `tel:${process.env.REACT_APP_MOBILE_NUMBER_1}`);handlePhoneClick();}}><BsFillTelephoneFill/></div>
              <span className="md:tw-hidden tw-font-playfairdisplay tw-ml-[5px] tw-cursor-default">{process.env.REACT_APP_MOBILE_NUMBER_1}</span>
            </a>
          </li>
          <li className="sm:tw-hidden md:tw-hidden">
            <a href="/about">
              <Title as="p" className="tw-text-[16px] tw-font-medium tw-text-gray-900 tw-cursor-pointer">
                About Us
              </Title>
            </a>
          </li>
          <li className="sm:tw-hidden md:tw-hidden">
            <a href="/mydharma">
              <Title as="p" className="tw-text-[16px] tw-font-medium tw-text-gray-900 tw-cursor-pointer">
                My Dharma
              </Title>
            </a>
          </li>
          <li className="sm:tw-hidden md:tw-hidden">
            <a href="/offerings" role="button" onClick={handleBookPujaClick}>
              <Title as="p" className="tw-text-[16px] tw-font-medium tw-text-gray-900 tw-cursor-pointer">
                Book a Puja
              </Title>
            </a>
          </li>
          {UserId ? (
          <li className="sm:tw-hidden md:tw-hidden">   
            <a href="/mybookings">
              <Title as="p" className="tw-text-[16px] tw-font-medium tw-text-gray-900 tw-mr-[20px] tw-cursor-pointer">
                My Bookings
              </Title>
            </a>
          </li>
          ) : null}
        </ul>
      </div>
      <a href="/menu">
        <Img
          src="/assets/images/menu_image.png"
          loading="lazy"
          alt="Menu-Image"
          className="tw-ml-6 tw-h-[44px] tw-w-[44px] tw-object-cover md:tw-ml-0 md:tw-max-w-none tw-cursor-pointer"
        />
      </a>
    </header>
  );
}
