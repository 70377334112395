import { Text } from "../../Component";
import ImportantDatesTracker from "../../Component/ImportantDatesTracker";
import React, { Suspense, useRef, useState, useEffect } from "react";
import { Slider } from "../../Component/Slider"; // Ensure this path is correct
import { IMPORTANT_DATES_GRID } from "../../Constants/myDharmaContent";

export default function DharmaAppFeaturesSection() {
  const sliderRef = useRef(null);
  const [sliderState, setSliderState] = useState(0);

  const handleSlideChange = (e) => {
    setSliderState(e?.item);
  };

  const handleDotClick = (index) => {
    if (sliderRef.current) {
      sliderRef.current.slideTo(
        index * (sliderRef.current.state.itemsInSlide || 1)
      );
    }     //changed the slides position
  };

  // Use effect to sync slider state with slider component
  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.slideTo(sliderState);
    }
  }, [sliderState]);

  return (
    <div>
      <div className="tw-flex tw-h-[724px] tw-items-center tw-justify-center tw-bg-[url('/public/assets/images/dharma_feature_background.webp')] tw-bg-cover tw-bg-no-repeat tw-py-20 md:tw-h-auto md:tw-py-5">
        <div className="container tw-flex tw-justify-center md:tw-px-5">
          <div className="tw-flex tw-w-full tw-flex-col tw-items-center tw-px-14 md:tw-px-5">  {/*adjusted the alignment */}
            <Text
              as="p"
              className="tw-text-[70px] tw-font-normal tw-text-primary_maroon md:tw-text-[30px] md:tw-text-center sm:tw-text-[30px]"
            >
              My Dharma App Features
            </Text>
            <div className="tw-w-full">
              <div className="md:tw-hidden tw-grid tw-w-full tw-w-[96%] tw-grid-cols-2 tw-justify-center tw-gap-10 md:tw-grid-cols-1">
                <Suspense fallback={<div>Loading feed...</div>}>
                  {IMPORTANT_DATES_GRID.map((d, index) => (
                    <ImportantDatesTracker
                      {...d}
                      key={"featuresGrid" + index}
                    />
                  ))}
                </Suspense>
              </div>
              <div className="tw-hidden md:tw-block tw-w-full">
                <Suspense fallback={<div>Loading feed...</div>}>
                  <Slider
                    ref={sliderRef}
                    items={IMPORTANT_DATES_GRID.map((d, index) => (
                      <ImportantDatesTracker
                        {...d}
                        key={"featuresSlider" + index}
                      />
                    ))}
                    onSlideChanged={handleSlideChange}
                    disableDotsControls
                    activeIndex={sliderState} // Ensure slider reflects state
                    autoPlay
                    autoPlayInterval={3000}
                  />
                </Suspense>
                <div className="tw-flex tw-items-center tw-justify-center tw-mt-4">
                  {[
                    ...Array(
                      Math.ceil(
                        IMPORTANT_DATES_GRID.length /
                          (sliderRef.current?.state?.itemsInSlide || 1)
                      )
                    ),
                  ].map((_, i) => (
                    <div key={i}>
                      <div
                        onClick={() => handleDotClick(i)}
                        className={`tw-mr-1.5 tw-inline-block tw-h-[6px] ${
                          sliderState >=
                            i * (sliderRef.current?.state?.itemsInSlide || 1) &&
                          sliderState <
                            (i + 1) *
                              (sliderRef.current?.state?.itemsInSlide || 1)
                            ? "tw-w-[38px] tw-bg-primary_maroon"
                            : "tw-w-[20px] tw-border tw-border-solid tw-border-primary_maroon"
                        } tw-cursor-pointer tw-rounded-[3px]`}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}